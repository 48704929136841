import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    flexGrow: 1
  },
  leftContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  logoIcon: {
    paddingTop: 5,
    marginRight: 8,
    height: 50
  },
  centerContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    textDecoration: 'none',
    color: 'white'
  },
  green: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  rightContainer: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  }
}))
