import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    '@media (max-width: 450px)': {
      width: '50%'
    },
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 24,
    backgroundColor: theme.palette.background.main,
    height: '80%',
    overflowY: 'scroll',
    overflow: 'hidden',
    padding: '20px'
  },
  label: {
    paddingTop: '10px'
  },
  button: {
    padding: '5px',
    margin: '10px'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '10%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  detail: {
    display: 'flex',
    width: '100%'
  },
  add: {
    margin: '10px',
    color: theme.palette.primary.main
  },
  remove: {
    float: 'right',
    cursor: 'pointer'
  }
}))
