import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  select: {
    width: '100%'
  },
  filter: {
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  input: {
    width: '16%',
    padding: '5px'
  },
  search: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between'
  },
  attach: {
    display: 'flex',
    padding: '10px',
    border: '10px',
    margin: '10px',
    height: '90%',
    backgroundColor: 'black'
  }
}))
