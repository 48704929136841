import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  generalInfo: {
    width: '100%',
    height: '100%',
    margin: '2px',
    padding: '10px',
    color: 'white'
  },
  hours: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '30%',
    padding: '15px'
  },
  filters: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 'bolder'
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  checkbox: {
    borderBottom: 'none !important'
  }
}))
