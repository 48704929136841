import { createSlice } from '@reduxjs/toolkit'
import consts from '../../consts'
import { HarvestCloneApi } from '../../services/api'
import role from '../role'
import name from './name'

export const userSlice = createSlice({
  name,
  initialState: {
    users: [],
    isWorking: false
  },
  reducers: {
    setUsers: (state, { payload }) => {
      state.users = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    }
  }
})

export const {
  setUsers,
  setIsWorking
} = userSlice.actions

export const fetchUsers = () => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get('users')
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setUsers(result.data || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const updateUser = (id, user, rolesList) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.patch(`users/${id}`, user)
    if (result.kind === consts.apiStatus.ok) {
      console.log({ rolesList })
      if (rolesList && rolesList?.length > 0) {
        dispatcher(role.actions.updateRoleBatch(rolesList, id))
      }
      dispatcher(fetchUsers())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const createUser = (user, rolesList) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.post('users', user)
    if (result.kind === consts.apiStatus.ok) {
      if (rolesList && rolesList?.length > 0) {
        dispatcher(role.actions.updateRoleBatch(rolesList, result?.data?.id))
      }
      dispatcher(fetchUsers())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteUser = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.delete(`users/${id}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchUsers())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export default userSlice.reducer
