import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  cellTitle: {
    fontWeight: 'bold'
  },
  green: {
    color: theme.palette.primary.main
  },
  cell: {
    width: '40%',
    transitionDelay: '0.1s'
  }
}))
