import React, { useState } from 'react'
import { Toolbar, AppBar } from '@material-ui/core'
import { useStyles } from './style'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Link } from 'react-router-dom'

const pages = [
  {
    to: '/hours',
    title: 'Working Hours'
  },
  {
    to: '/invoices',
    title: 'Invoices'
  },
  {
    to: '/inbound',
    title: 'Inbound'
  },
  {
    to: '/payments',
    title: 'Payments'
  }

]

export default function AppToolbar() {
  const [page, setPage] = useState(window.location.pathname)
  const classes = useStyles()

  return (
    <AppBar className={classes.appBar} position='fixed'>
      <Toolbar>
        <div className={classes.leftContainer}>
          <img src='/logo.png' className={classes.logoIcon} alt='ds commander logo' />
        </div>
        <div className={classes.centerContainer}>
          {pages.map(({ to, title }) => <Link key={to} to={to} onClick={() => { setPage(to) }} className={`${classes.link} ${page === to ? classes.green : ''}`}>{title}</Link>)}
        </div>
        <div className={classes.rightContainer}>
          <AccountCircleOutlinedIcon />
        </div>
      </Toolbar>

    </AppBar>
  )
}
