import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed'
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bolder'
  },
  id: {
    paddingLeft: '5px'
  },
  cell: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  icon: {
    cursor: 'pointer'
  },
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    '@media (max-width: 450px)': {
      width: '50%'
    },
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 24,
    backgroundColor: theme.palette.background.main,
    padding: '20px'
  },
  cancel: {
    backgroundColor: theme.palette.primary.main
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  select: {
    width: '400px',
    '@media (max-width: 450px)': {
      width: '50%'
    }
  },
  body: {
    display: 'flex',
    justifyContent: 'center'
  },
  list: {
    maxHeight: 170,
    overflow: 'auto'
  },
  info: {
    width: '100%'
  },
  icons: {
    width: '100px'
  },
  subitem: {
    paddingLeft: '10px'
  }
}))
