import { createSlice } from '@reduxjs/toolkit'
import consts from '../../consts'
import { HarvestCloneApi } from '../../services/api'
import name from './name'

export const invoiceItemCategorySlice = createSlice({
  name,
  initialState: {
    invoiceItemCategories: [],
    isWorking: false
  },
  reducers: {
    setInvoiceItemCategories: (state, { payload }) => {
      state.invoiceItemCategories = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    }
  }
})

export const {
  setIsWorking,
  setInvoiceItemCategories
} = invoiceItemCategorySlice.actions

export const fetchInvoiceItemCategories = () => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get('invoice_item_categories')
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setInvoiceItemCategories(result.data || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const updateInvoiceItemCategory = (id, invoiceItemCategory) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.patch(`invoice_item_categories/${id}`, invoiceItemCategory)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchInvoiceItemCategories())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const createInvoiceItemCategory = invoiceItemCategory => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.post('invoice_item_categories', invoiceItemCategory)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchInvoiceItemCategories())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteInvoiceItemCategory = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.delete(`invoice_item_categories/${id}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchInvoiceItemCategories())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteInvoiceItemCategoryBatch = invoiceItemCategoryIds => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    for (const id of invoiceItemCategoryIds) {
      await HarvestCloneApi.delete(`invoice_item_categories/${id}`)
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
  if (invoiceItemCategoryIds.length) {
    dispatcher(fetchInvoiceItemCategories())
  }
}

export default invoiceItemCategorySlice.reducer
