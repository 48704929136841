import { createSlice } from '@reduxjs/toolkit'
import consts from '../../consts'
import { HarvestCloneApi } from '../../services/api'
import { sortActives } from '../../utils'
import name from './name'

export const projectSlice = createSlice({
  name,
  initialState: {
    projects: [],
    isWorking: false,
    userAssignments: [],
    taskAssignments: []
  },
  reducers: {
    setProjects: (state, { payload }) => {
      state.projects = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    },
    setUserAssignments: (state, { payload }) => {
      state.userAssignments = payload
    },
    setTaskAssignments: (state, { payload }) => {
      state.taskAssignments = payload
    }
  }
})

export const {
  setProjects,
  setIsWorking,
  setUserAssignments,
  setTaskAssignments
} = projectSlice.actions

export const fetchProjects = (client_id, query) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get(`projects?${client_id ? `client_id=${client_id}` : ''}`, query)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setProjects(result.data.sort(sortActives) || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const updateProject = (id, project) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.patch(`projects/${id}`, project)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchProjects(project.client_id))
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const createProject = project => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.post('projects', project)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchProjects(project.client_id))
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteProject = (id, clientId) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.delete(`projects/${id}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchProjects(clientId))
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const getUserAssignments = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get(`projects/${id}/user_assignments`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setUserAssignments(result.data || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const getTaskAssignments = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get(`projects/${id}/task_assignments`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setTaskAssignments(result.data || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const createUserAssignment = (id, userAssignment) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    await HarvestCloneApi.post(`projects/${id}/user_assignments`, userAssignment)
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const createUpdateOrRemoveUserAssignmentBatch = (id, toAdd, toRemove, toUpdate) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    for (const assignment of toAdd) {
      await HarvestCloneApi.post(`projects/${id}/user_assignments`, assignment)
    }
    for (const assignmentId of toRemove) {
      await HarvestCloneApi.delete(`projects/${id}/user_assignments/${assignmentId}`)
    }
    for (const assignment of toUpdate) {
      await HarvestCloneApi.patch(`projects/${id}/user_assignments/${assignment.id}`, assignment)
    }
  } catch (error) {
    dispatcher(setIsWorking(false))
    console.log(error)
  }
  if (toAdd.length > 0 || toRemove.length > 0 || toUpdate.length > 0) {
    dispatcher(getUserAssignments(id))
  }
}

export const removeUserAssignment = (id, userAssignmentId) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    await HarvestCloneApi.delete(`projects/${id}/user_assignments/${userAssignmentId}`)
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const createTaskAssignment = (id, taskAssignment) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    await HarvestCloneApi.post(`projects/${id}/task_assignments`, taskAssignment)
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const createUpdateOrRemoveTaskAssignmentBatch = (id, toCreate, toDelete, toUpdate) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    for (const assignment of toCreate) {
      await HarvestCloneApi.post(`projects/${id}/task_assignments`, assignment)
    }
    for (const assignmentId of toDelete) {
      await HarvestCloneApi.delete(`projects/${id}/task_assignments/${assignmentId}`)
    }
    for (const assignment of toUpdate) {
      await HarvestCloneApi.patch(`projects/${id}/task_assignments/${assignment.id}`, assignment)
    }
  } catch (error) {
    dispatcher(setIsWorking(false))
    console.log(error)
  }
  if (toCreate.length > 0 || toDelete.length > 0 || toUpdate.length > 0) {
    dispatcher(getTaskAssignments(id))
  }
}

export const removeTaskAssignment = (id, taskAssignmentId) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    await HarvestCloneApi.delete(`projects/${id}/task_assignments/${taskAssignmentId}`)
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export default projectSlice.reducer
