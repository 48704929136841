import name from './name'

const selectSliceData = state => state[name]
const selectEntries = state => selectSliceData(state).entries
const selectProjects = state => selectSliceData(state).projects
const selectClients = state => selectSliceData(state).clients
const selectCollaborators = state => selectSliceData(state).collaborators
const selectCombinations = state => selectSliceData(state).combinations
const selectTasks = state => selectSliceData(state).tasks
const selectMonthRanges = state => selectSliceData(state).monthRanges

export {
  selectEntries,
  selectProjects,
  selectClients,
  selectCollaborators,
  selectCombinations,
  selectTasks,
  selectMonthRanges
}
