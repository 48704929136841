import { Box, Button, Collapse, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { Add, Delete, Edit, Remove } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateEditProjectModal, Title } from '../../../components'
import { client as clientSlice, local, project } from '../../../reducers'
import { useStyles } from './style'

const dateRegex = /\d{4}-\d{2}-\d{2}/
const AdminProjects = () => {
  const [selectedClient, setSelectedClient] = React.useState('')
  const [projectToEdit, setProjectToEdit] = React.useState({ name: '', id: '', client_id: '' })
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [projectToDelete, setProjectToDelete] = React.useState('')
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const clients = useSelector(clientSlice.selectors.selectClients)
  const projects = useSelector(project.selectors.selectProjects)
  const [openCreateEditProjectModal, setOpenCreateEditProjectModal] = React.useState(false)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const working = useSelector(local.selectors.selectIsWorking)
  const [client, setClient] = React.useState({})
  const [openList, setOpenList] = React.useState([])

  React.useEffect(() => {
    if (clients.find(client => client.id === selectedClient)) {
      setClient(clients.find(client => client.id === selectedClient))
      dispatcher(project.actions.fetchProjects(selectedClient))
    } else {
      dispatcher(project.actions.setProjects([]))
    }
  }, [selectedClient, clients, dispatcher])

  const showInfo = (label, info) => (info?.toString().length > 0 && <Typography variant='body2' style={{ paddingRight: '10px' }}><b>{label}</b>: {info.toString()}</Typography>)
  const openClose = projectId => setOpenList(openList => openList.indexOf(projectId) >= 0 ? openList.filter(id => id !== projectId) : [...openList, projectId])
  return (
    <div>
      <Title title='Projects' buttonText='Create Project' buttonAction={() => setOpenCreateEditProjectModal(true)} disableAction={!clients.find(client => client.id === selectedClient)} />
      <TableContainer>
        <Table className={classes.table}>
          <TableBody className={classes.body}>
            <Select className={classes.select} fullWidth id='client' value={selectedClient} onChange={e => setSelectedClient(e.target.value)}>
              {clients?.filter(client => client?.is_active).map(({ name, id }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
            </Select>
          </TableBody>
          {!working && selectedClient.length > 0 && projects?.length === 0 && <Typography align='center' style={{ padding: '20px' }}>The selected client doesn't have projects.</Typography>}
          {projects?.map(project =>
            <TableRow className={classes.row} key={clientSlice.client_id}>
              <TableCell className={classes.cell}>
                <div className={classes.title} style={!project?.is_active ? { color: 'grey' } : {}}>
                  <Typography variant='h6'>{project?.name}</Typography>
                  <Typography variant='body2' className={classes.id}>{project?.id}</Typography>
                  <Collapse in={openList.indexOf(project?.id) >= 0}>
                    <>
                      {showInfo('Code', project?.code)}
                      {showInfo('Is Billable', project?.is_billable)}
                      {showInfo('Is fixed fee', project?.is_fixed_fee)}
                      {showInfo('Bill by', project?.bill_by)}
                      {showInfo('Hourly rate', project?.hourly_rate)}
                      {showInfo('Budget', project?.budget)}
                      {showInfo('Currency', client?.currency)}
                      {showInfo('Budget', project?.budget)}
                      {showInfo('Budget by', project?.budget_by)}
                      {showInfo('Budget is monthly', project?.budget_is_monthly)}
                      {showInfo('Notify when over budget', project?.notify_when_over_budget)}
                      {showInfo('Over budget notification percentage', project?.over_budget_notification_percentage)}
                      {showInfo('Over budget notification date', project?.over_budget_notification_date?.match(dateRegex)[0])}
                      {showInfo('Show Budget to all', project?.show_budget_to_all)}
                      {showInfo('Cost Budget', project?.cost_budget)}
                      {showInfo('Cost Budget Include Expenses', project?.cost_budget_include_expenses)}
                      {showInfo('Fee', project?.fee)}
                      {showInfo('Starts On', project?.starts_on?.match(dateRegex)[0])}
                      {showInfo('Ends On', project?.ends_on?.match(dateRegex)[0])}
                      {showInfo('Notes', project?.notes)}
                    </>
                  </Collapse>
                </div>
                <div>
                  {openList.indexOf(project?.id) < 0 ? <Add onClick={() => openClose(project?.id)} className={classes.icon} />
                    : <Remove onClick={() => openClose(project?.id)} className={classes.icon} />}
                  <Edit onClick={() => { setProjectToEdit(project); setOpenEditModal(true) }} className={classes.icon} />
                  <Delete onClick={() => { setProjectToDelete(project); setOpenDeleteModal(true) }} className={classes.icon} />
                </div>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
      <CreateEditProjectModal
        open={openCreateEditProjectModal}
        setOpen={setOpenCreateEditProjectModal}
        projectToEdit={{ client_id: selectedClient }}
      />
      <CreateEditProjectModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        edit
        projectToEdit={projectToEdit}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirm={() => { dispatcher(project.actions.deleteProject(projectToDelete.id, selectedClient)); setOpenDeleteModal(false) }}
        client={clients.find(({ id }) => id === projectToDelete.client_id)}
        project={projects.find(({ id }) => id === projectToDelete.id)}
        classes={classes}
      />
    </div>
  )
}

const DeleteModal = ({ open, setOpen, confirm, client, project, classes }) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box className={classes.box}>
      <Typography>Are you sure you want to delete <b>{project?.name}</b> project from <b>{client?.name}</b>?</Typography>
      <div className={classes.buttons}>
        <Button onClick={confirm}>Delete</Button>
        <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
      </div>
    </Box>
  </Modal>
)

export default AdminProjects
