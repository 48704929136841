import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateEditTaskModal, Title } from '../../../components'
import { task } from '../../../reducers'
import { useStyles } from './style'

const AdminTasks = () => {
  const [taskToEdit, setTaskToEdit] = React.useState({ name: '', currency: '', id: '', address: '', is_active: undefined })
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [taskToDelete, setTaskToDelete] = React.useState('')
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const tasks = useSelector(task.selectors.selectTasks)
  const [openCreateEditTaskModal, setOpenCreateEditTaskModal] = React.useState(false)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const showInfo = (label, info) => (info?.toString().length > 0 && <Typography variant='body2' style={{ paddingRight: '10px' }}><b>{label}</b>: {info.toString()}</Typography>)
  return (
    <div>
      <Title title='Tasks' buttonText='Create Task' buttonAction={() => setOpenCreateEditTaskModal(true)} />
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            {tasks.map(task =>
              <TableRow className={classes.row} key={task.id}>
                <TableCell className={classes.cell}>
                  <div style={!task?.is_active ? { color: 'grey' } : {}}>
                    <div className={classes.title}>
                      <Typography variant='h6'>{task?.name}</Typography>
                      <Typography variant='body2' className={classes.id}>{task?.id}</Typography>
                    </div>
                    {showInfo('Billable by default', task?.billable_by_default)}
                    {showInfo('Default Hourly Rate', task?.default_hourly_rate)}
                    {showInfo('Is default', task?.is_default)}
                    {showInfo('Is active', task?.is_active)}
                  </div>
                  <div>
                    <Edit onClick={() => { setTaskToEdit(task); setOpenEditModal(true) }} className={classes.icon} />
                    <Delete onClick={() => { setTaskToDelete(task.id); setOpenDeleteModal(true) }} className={classes.icon} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateEditTaskModal
        open={openCreateEditTaskModal}
        setOpen={setOpenCreateEditTaskModal}
      />
      <CreateEditTaskModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        edit
        taskToEdit={taskToEdit}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirm={() => { dispatcher(task.actions.deleteTask(taskToDelete)); setOpenDeleteModal(false) }}
        task={tasks.find(({ id }) => id === taskToDelete)}
        classes={classes}
      />
    </div>
  )
}

const DeleteModal = ({ open, setOpen, confirm, task, classes }) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box className={classes.box}>
      <Typography>Are you sure you want to delete <b>{task?.name}</b> task?</Typography>
      <div className={classes.buttons}>
        <Button onClick={confirm}>Delete</Button>
        <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
      </div>
    </Box>
  </Modal>
)

export default AdminTasks
