import { Box, Button, FormControlLabel, InputLabel, Modal, Switch, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useStyles } from './style'
import { task as taskSlice } from '../../../reducers'

const init = [
  'name',
  'billable_by_default',
  'default_hourly_rate',
  'is_default',
  'is_active'
].reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {})
const CreateEditTaskModal = ({ edit, open, setOpen, taskToEdit = {} }) => {
  const [task, setTask] = React.useState(init)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const [diff, setDiff] = React.useState(false)

  const saveTask = () => {
    if (edit) {
      dispatcher(taskSlice.actions.updateTask(taskToEdit.id, task))
    } else {
      dispatcher(taskSlice.actions.createTask(task))
    }
    setOpen(false)
  }

  React.useEffect(() => {
    if (edit) {
      const initialState = {}
      Object.keys(init).forEach(key => { initialState[key] = taskToEdit[key] })
      setTask(initialState)
    }
  }, [edit, taskToEdit])

  React.useEffect(() => {
    const initialState = {}
    Object.keys(task).forEach(key => { initialState[key] = taskToEdit[key] })
    setDiff(JSON.stringify(initialState) !== JSON.stringify(task))
  }, [task, taskToEdit])

  const setValue = (key, e) => setTask(task => ({ ...task, [key]: e.target.value }))

  return (
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <Box className={classes.box}>
        <Typography align='center' variant='h6'>{edit ? 'Edit' : 'New'} Task</Typography>
        <div>
          <InputLabel id='name' className={classes.label}>Name</InputLabel>
          <TextField id='name' value={task?.name} onChange={e => setValue('name', e)} fullWidth required />
          <FormControlLabel control={<Switch checked={Boolean(task?.billable_by_default)} onChange={e => setValue('billable_by_default', { target: { value: !task?.billable_by_default } })} />} label='Billable by default' />
          <InputLabel id='default_hourly_rate' className={classes.label}>Default Hourly Rate</InputLabel>
          <TextField type='number' id='default_hourly_rate' fullWidth value={task?.default_hourly_rate} onChange={e => setValue('default_hourly_rate', e)} />
          <FormControlLabel control={<Switch checked={Boolean(task?.is_default)} onChange={e => setValue('is_default', { target: { value: !task?.is_default } })} />} label='Is Default' />
          <FormControlLabel control={<Switch checked={Boolean(task?.is_active)} onChange={e => setValue('is_active', { target: { value: !task?.is_active } })} />} label='Is Active' />
        </div>
        <Button disabled={!diff} onClick={saveTask} className={classes.button}>{edit ? 'Save' : 'Create'}</Button>
      </Box>
    </Modal>
  )
}

export default CreateEditTaskModal
