import { createSlice } from '@reduxjs/toolkit'
import consts from '../../consts'
import { HarvestCloneApi } from '../../services/api'
import name from './name'

export const roleSlice = createSlice({
  name,
  initialState: {
    roles: [],
    isWorking: false
  },
  reducers: {
    setRoles: (state, { payload }) => {
      state.roles = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    }
  }
})

export const {
  setRoles,
  setIsWorking
} = roleSlice.actions

export const fetchRoles = () => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get('roles')
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setRoles(result.data || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const updateRole = (id, role) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.patch(`roles/${id}`, role)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchRoles())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const createRole = role => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.post('roles', role)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchRoles())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteRole = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.delete(`roles/${id}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchRoles())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteRoleBatch = roleIds => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    for (const id of roleIds) {
      await HarvestCloneApi.delete(`roles/${id}`)
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
  if (roleIds.length) {
    dispatcher(fetchRoles())
  }
}

export const updateRoleBatch = (roleIds, userId) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    for (const id of roleIds) {
      await HarvestCloneApi.patch(`roles/${id}`, { user_ids: [userId] })
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
  if (roleIds.length) {
    dispatcher(fetchRoles())
  }
}

export default roleSlice.reducer
