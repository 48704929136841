import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    '@media (max-width: 450px)': {
      width: '50%'
    },
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 24,
    backgroundColor: theme.palette.background.main,
    padding: '20px'
  },
  label: {
    paddingTop: '10px'
  },
  button: {
    padding: '5px',
    marginTop: '35px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))
