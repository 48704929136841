import React, { useEffect, useState } from 'react'
import { useStyles } from './style'
import MarkdownViewer from '../../components/markdown-viewer'
import README from './readme.md'

const FormExample = () => {
  const classes = useStyles()
  const [text, setText] = useState('')

  useEffect(() => {
    fetch(README)
      .then(res => res.text())
      .then(md => setText(md))
      .catch(err => console.error(err))
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <MarkdownViewer children={text} />
      </div>
    </div>
  )
}

export default FormExample
