import name from './name'

const selectSliceData = state => state[name]
const selectProjects = state => selectSliceData(state).projects
const selectIsWorking = state => selectSliceData(state).isWorking
const selectUserAssignments = state => selectSliceData(state).userAssignments
const selectTaskAssignments = state => selectSliceData(state).taskAssignments

export {
  selectProjects,
  selectIsWorking,
  selectUserAssignments,
  selectTaskAssignments
}
