import name from './name'

const selectSliceData = state => state[name]
const selectRoles = state => selectSliceData(state).roles
const selectIsWorking = state => selectSliceData(state).isWorking

export {
  selectRoles,
  selectIsWorking
}
