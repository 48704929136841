import name from './name'

const selectSliceData = state => state[name]
const selectFilteredClients = state => selectSliceData(state).filteredClients
const selectStartDate = state => selectSliceData(state).startDate
const selectEndDate = state => selectSliceData(state).endDate
const selectFilteredEntries = state => selectSliceData(state).filteredEntries
const selectGroupedEntriesByMonth = state => selectSliceData(state).groupedEntriesByMonth
const selectLastThreeMonthsEntries = state => selectSliceData(state).lastThreeMonthsEntries
const selectLastThreeMonthsGroupEntries = state => selectSliceData(state).lastThreeMonthsGroupEntries
const selectLastThreeMonthsEntriesSumByMonth = state => selectSliceData(state).lastThreeMonthsEntriesSumByMonth
const selectFilteredTasks = state => selectSliceData(state).filteredTasks
const selectSearch = state => selectSliceData(state).search

export {
  selectFilteredClients,
  selectStartDate,
  selectEndDate,
  selectFilteredEntries,
  selectGroupedEntriesByMonth,
  selectLastThreeMonthsEntries,
  selectLastThreeMonthsGroupEntries,
  selectLastThreeMonthsEntriesSumByMonth,
  selectFilteredTasks,
  selectSearch
}
