import { Box, Button, FormControlLabel, InputLabel, MenuItem, Modal, Select, Switch, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useStyles } from './style'
import { client as clientSlice } from '../../../reducers'

const currencies = [
  { name: 'BRL', symbol: 'R$' },
  { name: 'GBP', symbol: '£' },
  { name: 'USD', symbol: 'U$' }
]

const init = [
  'name',
  'currency',
  'is_active',
  'address'
].reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {})
const CreateEditClientModal = ({ edit, open, setOpen, clientToEdit = {} }) => {
  const [client, setClient] = React.useState(init)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const [diff, setDiff] = React.useState(false)

  const saveClient = () => {
    if (edit) {
      dispatcher(clientSlice.actions.updateClient(clientToEdit.id, client))
    } else {
      dispatcher(clientSlice.actions.createClient(client))
    }
    setOpen(false)
  }

  React.useEffect(() => {
    if (edit) {
      const initialState = {}
      Object.keys(init).forEach(key => { initialState[key] = clientToEdit[key] })
      setClient(initialState)
    }
  }, [edit, clientToEdit])

  React.useEffect(() => {
    const initialState = {}
    Object.keys(client).forEach(key => { initialState[key] = clientToEdit[key] })
    setDiff(JSON.stringify(initialState) !== JSON.stringify(client))
  }, [client, clientToEdit])

  const setValue = (key, e) => setClient(client => ({ ...client, [key]: e.target.value }))

  return (
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <Box className={classes.box}>
        <Typography align='center' variant='h6'>{edit ? 'Edit' : 'New'} Client</Typography>
        <div>
          <InputLabel id='name' className={classes.label}>Name</InputLabel>
          <TextField id='name' value={client?.name} onChange={e => setValue('name', e)} fullWidth required />
          <InputLabel id='currency' className={classes.label}>Currency</InputLabel>
          <Select id='currency' fullWidth value={client?.currency} onChange={e => setValue('currency', e)}>
            {currencies.map(({ name, symbol }) => <MenuItem key={name} value={name}>{symbol} {name}</MenuItem>)}
          </Select>
          <FormControlLabel control={<Switch checked={Boolean(client?.is_active)} onChange={e => setValue('is_active', { target: { value: !client?.is_active } })} />} label='Is Active' />
          <InputLabel id='address' className={classes.label}>Address</InputLabel>
          <TextField id='address' fullWidth value={client?.address} onChange={e => setValue('address', e)} />
        </div>
        <Button disabled={!diff} onClick={saveClient} className={classes.button}>{edit ? 'Save' : 'Create'}</Button>
      </Box>
    </Modal>
  )
}

export default CreateEditClientModal
