import React from 'react'
import { Button, LinearProgress, Typography } from '@material-ui/core'
import { useStyles } from './style'
import { useSelector } from 'react-redux'
import { local } from '../../../reducers'

const Title = ({ title, buttonText, buttonAction, disableAction = false, extraComponent = null }) => {
  const classes = useStyles()
  const isWorking = useSelector(local.selectors.selectIsWorking)

  return (
    <>
      <div className={classes.pageTitle}>
        <Typography variant='h4'>{title}</Typography>
        <div>
          {buttonText && buttonAction && <Button disabled={disableAction} onClick={buttonAction}>{buttonText}</Button>}
          {extraComponent && React.createElement(extraComponent)}
        </div>
      </div>
      {isWorking && <LinearProgress />}
    </>
  )
}
export default Title
