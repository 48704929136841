import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateEditClientModal, Title } from '../../../components'
import { client } from '../../../reducers'
import { useStyles } from './style'

const AdminClients = () => {
  const [clientToEdit, setClientToEdit] = React.useState({ name: '', currency: '', id: '', address: '', is_active: undefined })
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [clientToDelete, setClientToDelete] = React.useState('')
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const clients = useSelector(client.selectors.selectClients)
  const [openCreateEditClientModal, setOpenCreateEditClientModal] = React.useState(false)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const showInfo = (label, info) => (info?.toString().length > 0 && <Typography variant='body2' style={{ paddingRight: '10px' }}><b>{label}</b>: {info.toString()}</Typography>)
  return (
    <div>
      <Title title='Clients' buttonText='Create Client' buttonAction={() => setOpenCreateEditClientModal(true)} />
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            {clients.map(client =>
              <TableRow className={classes.row} key={client.id}>
                <TableCell className={classes.cell}>
                  <div style={!client?.is_active ? { color: 'grey' } : {}}>
                    <div className={classes.title}>
                      <Typography variant='h6'>{client?.name}</Typography>
                      <Typography variant='body2' className={classes.id}>{client?.id}</Typography>
                    </div>
                    {showInfo('Currency', client?.currency)}
                    {showInfo('Address', client?.address)}
                    {showInfo('Creation Date', moment(client?.created_at).format('DD/MM/YYYY'))}
                  </div>
                  <div>
                    <Edit onClick={() => { setClientToEdit(client); setOpenEditModal(true) }} className={classes.icon} />
                    <Delete onClick={() => { setClientToDelete(client.id); setOpenDeleteModal(true) }} className={classes.icon} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateEditClientModal
        open={openCreateEditClientModal}
        setOpen={setOpenCreateEditClientModal}
      />
      <CreateEditClientModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        edit
        clientToEdit={clientToEdit}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirm={() => { dispatcher(client.actions.deleteClient(clientToDelete)); setOpenDeleteModal(false) }}
        client={clients.find(({ id }) => id === clientToDelete)}
        classes={classes}
      />
    </div>
  )
}

const DeleteModal = ({ open, setOpen, confirm, client, classes }) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box className={classes.box}>
      <Typography>Are you sure you want to delete <b>{client?.name}</b> client?</Typography>
      <div className={classes.buttons}>
        <Button onClick={confirm}>Delete</Button>
        <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
      </div>
    </Box>
  </Modal>
)

export default AdminClients
