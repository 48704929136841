import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import { useStyles } from './style'
import AdminPages from '../../pages/adminPages'
import { client, local, project, role, task, user, invoice, invoiceItemCategory } from '../../reducers'

const pages = [
  {
    name: 'Clients',
    component: 'clients'
  },
  {
    name: 'Projects',
    component: 'projects'
  },
  {
    name: 'Tasks',
    component: 'tasks'
  },
  {
    name: 'Users',
    component: 'users'
  },
  {
    name: 'Assignes',
    component: 'assignes'
  },
  {
    name: 'Invoices',
    component: 'invoices'
  }
]

export default function Admin() {
  const dispatcher = useDispatch()
  const clientIsWorking = useSelector(client.selectors.selectIsWorking)
  const projectIsWorking = useSelector(project.selectors.selectIsWorking)
  const roleIsWorking = useSelector(role.selectors.selectIsWorking)
  const userIsWorking = useSelector(user.selectors.selectIsWorking)
  const tasksIsWorking = useSelector(task.selectors.selectIsWorking)
  const invoicesIsWorking = useSelector(invoice.selectors.selectIsWorking)
  const invoiceItemsCategoryIsWorking = useSelector(invoiceItemCategory.selectors.selectIsWorking)
  const isWorking = clientIsWorking || projectIsWorking || roleIsWorking || userIsWorking || tasksIsWorking || invoicesIsWorking || invoiceItemsCategoryIsWorking
  React.useEffect(() => {
    dispatcher(local.actions.setIsWorking(isWorking))
  }, [dispatcher, isWorking])
  React.useEffect(() => {
    dispatcher(client.actions.fetchClients())
    dispatcher(task.actions.fetchTasks())
    dispatcher(user.actions.fetchUsers())
    dispatcher(role.actions.fetchRoles())
    dispatcher(invoiceItemCategory.actions.fetchInvoiceItemCategories())
  }, [dispatcher])
  const classes = useStyles()
  const [page, setPage] = React.useState('clients')
  return (
    <div className={classes.drawerWithPage}>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
        anchor='left'
        variant='permanent'
      >
        <div className={classes.drawerContainer}>
          <List>
            {pages.map(p =>
              <ListItem disabled={isWorking} className={page === p.component ? classes.selected : ''} key={p.name} onClick={() => setPage(p.component)} button>
                <ListItemText className={classes.page} primary={p.name} />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
      <div className={classes.page}>
        {React.createElement(AdminPages[page])}
      </div>
    </div>
  )
}
