import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AppToolbar } from './components'
import Routes from './routes'
// import AppDrawer from './components/app-drawer'
import { useStyles } from './style'
import Logger from './utils/logger'
import { CustomThemeProvider } from './contexts/theme'
// import RootContextProvider from './contexts/root'

function App () {
  const classes = useStyles()
  return (
    // <RootContextProvider>
    <CustomThemeProvider>
      <div className={classes.root}>
        <Logger />
        <CssBaseline />
        <AppToolbar />
        {/* <AppDrawer /> */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Routes />
        </main>

      </div>
    </CustomThemeProvider>
    // </RootContextProvider>
  )
}

export default App
