import name from './name'

const selectSliceData = state => state[name]
const selectFilteredProjects = state => selectSliceData(state).filteredProjects
const selectFilteredCollaborators = state => selectSliceData(state).filteredCollaborators
const selectFilteredClients = state => selectSliceData(state).filteredClients
const selectStartDate = state => selectSliceData(state).startDate
const selectEndDate = state => selectSliceData(state).endDate
const selectFilteredEntries = state => selectSliceData(state).filteredEntries
const selectGroupedEntries = state => selectSliceData(state).groupedEntries
const selectLastThreeMonthsEntries = state => selectSliceData(state).lastThreeMonthsEntries
const selectLastThreeMonthsGroupEntries = state => selectSliceData(state).lastThreeMonthsGroupEntries
const selectLastThreeMonthsEntriesSumByMonth = state => selectSliceData(state).lastThreeMonthsEntriesSumByMonth
const selectFilteredTasks = state => selectSliceData(state).filteredTasks
const selectSearch = state => selectSliceData(state).search

export {
  selectFilteredProjects,
  selectFilteredCollaborators,
  selectFilteredClients,
  selectStartDate,
  selectEndDate,
  selectFilteredEntries,
  selectGroupedEntries,
  selectLastThreeMonthsEntries,
  selectLastThreeMonthsGroupEntries,
  selectLastThreeMonthsEntriesSumByMonth,
  selectFilteredTasks,
  selectSearch
}
