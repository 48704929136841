import { Collapse, Table, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { AddSharp, Remove } from '@material-ui/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { WorkingHoursSlice } from '../../reducers'
import WorkingHoursListEntries from '../workinghours-list-entries'
import { useStyles } from './style'

const WorkingHoursTable = () => {
  const [expand, setExpand] = React.useState(null)
  const classes = useStyles()
  const groupedEntries = useSelector(WorkingHoursSlice.selectors.selectGroupedEntries)

  return (
    <TableContainer>
      <Table fullWidth>
        {groupedEntries.map((entry, i) =>
          <>
            <TableRow key={i}>
              <TableCell style={{ borderBottom: expand === i ? 'none' : '' }}>
                <Typography variant='body1' className={classes.cellTitle}>
                  {entry.project.name}
                </Typography>
                <Typography variant='body2' className={classes.user}>
                  {entry.user.name}
                </Typography>
                <Typography variant='body2' className={classes.client}>
                  {entry.client.name}
                </Typography>
              </TableCell>
              <TableCell style={{ borderBottom: expand === i ? 'none' : '' }}>
                <Typography variant='body1' className={classes.cellTitle}>
                  Hours
                </Typography>
                <Typography variant='h6' className={classes.green}>
                  {entry.hours.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell style={{ borderBottom: expand === i ? 'none' : '' }}>
                <Typography variant='body1' className={classes.cellTitle}>
                  Rate/h
                </Typography>
                <Typography variant='h6' className={classes.green}>
                  {entry.budget}
                </Typography>
              </TableCell>
              <TableCell style={{ borderBottom: expand === i ? 'none' : '' }}>
                <Typography variant='body1' className={classes.cellTitle}>
                  Acc
                </Typography>
                <Typography variant='h6' className={classes.green}>
                  {entry.budget * entry.hours}
                </Typography>
              </TableCell>
              <TableCell width='2px' style={{ borderBottom: expand === i ? 'none' : '' }}>
                {i === expand ? <Remove onClick={() => setExpand(null)} style={{ cursor: 'pointer' }} /> : <AddSharp onClick={() => setExpand(i)} style={{ cursor: 'pointer' }} />}
              </TableCell>
            </TableRow>
            <td colSpan='5'>
              <Collapse in={expand === i}>
                <WorkingHoursListEntries entry={entry} />
              </Collapse>
            </td>
          </>
        )}
      </Table>
    </TableContainer>
  )
}
export default WorkingHoursTable
