import { TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './style'

const InboundListEntries = ({ entry: { range } }) => {
  const classes = useStyles()
  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.cell}>
        <Typography>Due date</Typography>
        {range.start}
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography>Send date</Typography>
        {range.end}
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography>Payment date</Typography>
        {range.end}
      </TableCell>
    </TableRow>
  )
}
export default InboundListEntries
