import name from './name'

const selectSliceData = state => state[name]
const selectInvoices = state => selectSliceData(state).invoices
const selectIsWorking = state => selectSliceData(state).isWorking

export {
  selectInvoices,
  selectIsWorking
}
