import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed',
    height: 'max-content'
  },
  tableContainer: {
    height: '600px'
  },
  side: {
    margin: '5px',
    padding: '5px'
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bolder',
    paddingLeft: '10px'
  },
  id: {
    paddingLeft: '5px'
  },
  cell: {
    display: 'flex',
    flexDirection: 'column'
  },
  projectsCell: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    cursor: 'pointer'
  },
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    '@media (max-width: 450px)': {
      width: '50%'
    },
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 24,
    backgroundColor: theme.palette.background.main,
    padding: '20px',
    margin: '20px'
  },
  cancel: {
    backgroundColor: theme.palette.primary.main
  },
  save: {
    backgroundColor: theme.palette.primary.main,
    margin: '10px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  select: {
    width: '400px',
    '@media (max-width: 450px)': {
      width: '50%'
    },
    margin: '10px'
  },
  body: {
    display: 'flex',
    paddingTop: '5px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignContent: 'center'
  },
  tables: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  info: {
    display: 'flex',
    flexDirection: 'row'
  }
}))
