import React from 'react'
import { useStyles } from './style'
import { EntriesSlice, PaymentsSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import timeEntries from '../../__mocks__/time_entries.json'
import { Button, Card, InputLabel, TextField } from '@material-ui/core'
import moment from 'moment'

export default function PaymentsFilter () {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const entries = useSelector(EntriesSlice.selectors.selectEntries)
  const projects = useSelector(EntriesSlice.selectors.selectProjects)
  const clients = useSelector(EntriesSlice.selectors.selectClients)
  const startDate = useSelector(PaymentsSlice.selectors.selectStartDate)
  const endDate = useSelector(PaymentsSlice.selectors.selectEndDate)
  const combinations = useSelector(EntriesSlice.selectors.selectCombinations)
  const filteredTasks = useSelector(PaymentsSlice.selectors.selectFilteredTasks)
  const tasks = useSelector(EntriesSlice.selectors.selectTasks)
  const search = useSelector(PaymentsSlice.selectors.selectSearch)
  const monthRanges = useSelector(EntriesSlice.selectors.selectMonthRanges)
  const [filterPaymentsExtras, setFilterPaymentsExtras] = React.useState('payments')
  const [localSearch, setLocalSearch] = React.useState('')

  const setStartDate = ({ target: { value } }) => {
    if (!endDate || moment(value) <= moment(endDate)) {
      dispatcher(PaymentsSlice.actions.setStartdate(value))
    }
  }
  const setEndDate = ({ target: { value } }) => {
    if (!startDate || moment(value) >= moment(startDate)) {
      dispatcher(PaymentsSlice.actions.setEndDate(value))
    }
  }
  const applySearch = () => {
    dispatcher(PaymentsSlice.actions.setSearch(localSearch))
  }

  React.useEffect(() => {
    const selectEntries = entries
      .filter(entry => search.length === 0 || JSON.stringify(entry).toLocaleLowerCase().indexOf(search.toLowerCase()) >= 0)
      .filter(({ task }) => !filteredTasks.length || filteredTasks.find(({ id }) => task.id === id))
      .filter(({ spent_date: spentDate }) => {
        if (startDate && endDate) return moment(spentDate) >= moment(startDate) && moment(spentDate) <= moment(endDate)
        if (startDate) return moment(spentDate) >= moment(startDate)
        if (endDate) return moment(spentDate) >= moment(endDate)
        return true
      })
    dispatcher(PaymentsSlice.actions.setFilteredEntries(selectEntries))

    const groupEntriesByMonth = monthRanges.map(({ start, end }) => clients
      .map(({ id: clientId }) =>
        selectEntries
          .filter(({ client }) => client.id === clientId)
          .filter(({ spent_date: spentDate }) => moment(spentDate) >= moment(start) && moment(spentDate) <= moment(end))
          .reduce((acc, curr) => ({ ...acc, ...curr, hours: acc.hours + (curr.hours ?? 0) }), { hours: 0, range: { start, end } })
      ).filter(({ id }) => id))
    dispatcher(PaymentsSlice.actions.setGroupedEntriesByMonth(groupEntriesByMonth))

    const lastThreeMonthsStartDate = moment().startOf('month').subtract(3, 'months')
    const lastThreeMonthsEndDate = moment().endOf('month').subtract(1, 'months')
    const lastThreeMonthsEntries = entries
      .filter(({ spent_date: spentDate }) => moment(spentDate) >= moment(lastThreeMonthsStartDate) && moment(spentDate) <= moment(lastThreeMonthsEndDate))
    dispatcher(PaymentsSlice.actions.setLastThreeMonthsEntries(lastThreeMonthsEntries))
    const lastThreeMonthsEntriesSumByMonth = [1, 2, 3].map(
      subtract => {
        const range = { start: moment().startOf('month').subtract(subtract, 'months'), end: moment().endOf('month').subtract(subtract, 'months') }
        return clients
          .map(({ id: clientId }) =>
            lastThreeMonthsEntries
              .filter(({ client }) => client?.id === clientId)
              .filter(({ spent_date: spentDate }) => moment(spentDate) >= moment(range.start) && moment(spentDate) <= moment(range.end))
              .reduce((acc, curr) => ({ ...curr, hours: acc.hours + (curr.hours ?? 0) }), { hours: 0 }))
      }
    )
    dispatcher(PaymentsSlice.actions.setLastThreeMonthsEntriesSumByMonth(lastThreeMonthsEntriesSumByMonth))
    const lastThreeMonthsGroupEntries = combinations
      .map(({ id: clientId }) =>
        lastThreeMonthsEntries
          .filter(({ client }) => client.id === clientId)
          .reduce((acc, curr) => ({ ...curr, hours: acc.hours + (curr.hours ?? 0) }), { hours: 0 })
      ).filter(({ id }) => id)
    dispatcher(PaymentsSlice.actions.setLastThreeMonthsGroupEntries(lastThreeMonthsGroupEntries))
  }, [dispatcher, entries, startDate, endDate, clients, projects, combinations, filteredTasks, tasks, search, monthRanges])
  React.useEffect(() => {
    dispatcher(EntriesSlice.actions.setEntries(timeEntries))
  }, [dispatcher])
  return (
    <Card className={classes.filter}>
      <div className={classes.filterPaymentsExtra}>
        <Button disableFocusRipple disableRipple onClick={() => setFilterPaymentsExtras('payments')} className={filterPaymentsExtras === 'payments' ? classes.selectedButton : classes.button}>Payments</Button>
        <Button disableFocusRipple disableRipple onClick={() => setFilterPaymentsExtras('extras')} className={filterPaymentsExtras === 'extras' ? classes.selectedButton : classes.button}>Extras</Button>
      </div>
      <div className={classes.input}>
        <InputLabel>Start Date</InputLabel>
        <TextField className={classes.select} onChange={setStartDate} value={startDate} type='date' name='start_date' />
      </div>
      <div className={classes.input}>
        <InputLabel>End Date</InputLabel>
        <TextField className={classes.select} onChange={setEndDate} value={endDate} type='date' name='end_date' />
      </div>
      <div className={classes.input}>
        <InputLabel>Search</InputLabel>
        <div className={classes.search}>
          <TextField type='text' value={localSearch} onChange={({ target: { value } }) => setLocalSearch(value)} />
          <Button style={{ padding: '1px' }} onClick={applySearch}>Search</Button>
        </div>
      </div>
    </Card>
  )
}
