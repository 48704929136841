import { Box, Button, FormControlLabel, InputLabel, MenuItem, Modal, Select, Switch, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { project as projectSlice } from '../../../reducers'
import { useStyles } from './style'

const init = [
  'name',
  'code',
  'is_active',
  'is_billable',
  'is_fixed_fee',
  'bill_by',
  'hourly_rate',
  'budget',
  'budget_by',
  'budget_is_monthly',
  'notify_when_over_budget',
  'over_budget_notification_percentage',
  'over_budget_notification_date',
  'show_budget_to_all',
  'cost_budget',
  'cost_budget_include_expenses',
  'fee',
  'notes',
  'starts_on',
  'ends_on'
].reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {})

const CreateEditProjectModal = ({ edit, open, setOpen, projectToEdit }) => {
  const [project, setProject] = React.useState(init)
  const [projectId, setProjectId] = React.useState('')
  const [clientId, setClientId] = React.useState('')
  const [diff, setDiff] = React.useState(false)
  const [allRequired, setAllRequired] = React.useState(false)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const saveProject = () => {
    if (edit) {
      dispatcher(projectSlice.actions.updateProject(projectId, { ...project, bill_by: project?.is_billable ? project?.bill_by : 'none', client_id: clientId }))
    } else {
      dispatcher(projectSlice.actions.createProject({ ...project, bill_by: project?.is_billable ? project?.bill_by : 'none', client_id: clientId }))
    }
    setProject({})
    setOpen(false)
  }

  const formatDate = date => date?.match(/\d{4}-\d{2}-\d{2}/)[0]

  React.useEffect(() => {
    setClientId(projectToEdit?.client_id)
    setProjectId(projectToEdit?.id)
    if (edit) {
      const initialState = {}
      Object.keys(init).forEach(key => { initialState[key] = projectToEdit[key] })
      setProject(initialState)
    }
  }, [edit, projectToEdit])

  React.useEffect(() => {
    const initialState = {}
    Object.keys(project).forEach(key => { initialState[key] = projectToEdit[key] })
    setDiff(JSON.stringify(initialState) !== JSON.stringify(project))
    setAllRequired(project?.name?.length > 0 && project?.is_billable !== undefined && (project?.is_billable ? project?.bill_by !== undefined : true) && project?.budget_by !== undefined)
  }, [project, projectToEdit])

  const setValue = (key, e) => setProject(project => ({ ...project, [key]: e.target.value }))

  return (
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <Box className={classes.box}>
        <Typography align='center' variant='h6'>{edit ? 'Edit' : 'New'} Project</Typography>
        <InputLabel id='name' className={classes.label}>Name*</InputLabel>
        <TextField id='name' value={project?.name} onChange={e => setValue('name', e)} fullWidth required />
        <InputLabel id='code' className={classes.label}>Code</InputLabel>
        <TextField id='code' value={project?.code} onChange={e => setValue('code', e)} fullWidth />
        <FormControlLabel control={<Switch checked={Boolean(project?.is_active)} onChange={e => setValue('is_active', { target: { value: !project?.is_active } })} />} label='Is Active' />
        <FormControlLabel control={<Switch checked={Boolean(project?.is_billable)} onChange={e => setValue('is_billable', { target: { value: !project?.is_billable } })} />} label='Is billable*' />
        <FormControlLabel control={<Switch checked={Boolean(project?.is_fixed_fee)} onChange={e => setValue('is_fixed_fee', { target: { value: !project?.is_fixed_fee } })} />} label='Is Fixed Feed' />
        <InputLabel id='hourly_rate' className={classes.label}>Hourly Rate</InputLabel>
        <TextField type='number' id='hourly_rate' value={project?.hourly_rate} onChange={e => setValue('hourly_rate', e)} fullWidth required />
        <InputLabel id='bill_by' className={classes.label}>Bill by*</InputLabel>
        <Select disabled={!project?.is_billable} className={classes.select} fullWidth id='bill_by' value={project?.bill_by} onChange={e => setValue('bill_by', e)}>
          <MenuItem value='Project'>Project</MenuItem>
          <MenuItem value='Tasks'>Tasks</MenuItem>
          <MenuItem value='People'>People</MenuItem>
        </Select>
        <InputLabel id='budget_by' className={classes.label}>Budget by*</InputLabel>
        <Select className={classes.select} fullWidth id='budget_by' value={project?.budget_by} onChange={e => setValue('budget_by', e)}>
          <MenuItem value='project'>Hours Per Project</MenuItem>
          <MenuItem value='project_cost'>Total Project Fees</MenuItem>
          <MenuItem value='task'>Hours Per Task</MenuItem>
          <MenuItem value='task_fees'>Fees Per Task</MenuItem>
          <MenuItem value='person'>Hours Per Person</MenuItem>
          <MenuItem value='none'>No Budget</MenuItem>
        </Select>
        <FormControlLabel control={<Switch checked={Boolean(project?.budget_is_monthly)} onChange={e => setValue('budget_is_monthly', { target: { value: !project?.budget_is_monthly } })} />} label='Budget is monthly' />
        <FormControlLabel control={<Switch checked={Boolean(project?.notify_when_over_budget)} onChange={e => setValue('notify_when_over_budget', { target: { value: !project?.notify_when_over_budget } })} />} label='Notify when over budget' />
        <InputLabel id='over_budget_notification_percentage' className={classes.label}>Over Budget Notification (%)</InputLabel>
        <TextField type='number' id='over_budget_notification_percentage' value={project?.over_budget_notification_percentage} onChange={e => setValue('over_budget_notification_percentage', e)} fullWidth />
        <InputLabel id='cost_budget' className={classes.label}>Cost Budget</InputLabel>
        <TextField type='number' id='cost_budget' value={project?.cost_budget} onChange={e => setValue('cost_budget', e)} fullWidth />
        <InputLabel id='over_budget_notification_date' className={classes.label}>Over Budget Notification Date</InputLabel>
        <TextField type='date' id='over_budget_notification_date' value={formatDate(project?.over_budget_notification_date)} onChange={e => setValue('over_budget_notification_date', e)} fullWidth />
        <FormControlLabel control={<Switch checked={Boolean(project?.cost_budget_include_expenses)} onChange={e => setValue('cost_budget_include_expenses', { target: { value: !project?.cost_budget_include_expenses } })} />} label='Cost Budget Include Expenses' />
        <FormControlLabel control={<Switch checked={Boolean(project?.fee)} onChange={e => setValue('fee', { target: { value: !project?.fee } })} />} label='Fee' />
        <InputLabel id='notes' className={classes.label}>Notes</InputLabel>
        <TextField type='text' multiline id='notes' value={project?.notes} onChange={e => setValue('notes', e)} fullWidth />
        <InputLabel id='starts_on' className={classes.label}>Starts on</InputLabel>
        <TextField type='date' id='starts_on' value={formatDate(project?.starts_on)} onChange={e => setValue('starts_on', e)} fullWidth />
        <InputLabel id='ends_on' className={classes.label}>Ends on</InputLabel>
        <TextField type='date' id='ends_on' value={formatDate(project?.ends_on)} onChange={e => setValue('ends_on', e)} fullWidth />
        <Button disabled={edit ? !diff : !allRequired} onClick={saveProject} className={classes.button}>{edit ? 'Save' : 'Create'}</Button>
      </Box>
    </Modal>
  )
}

export default CreateEditProjectModal
