import React from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useStyles } from './style'
import CounterRealtime from '../../reducers/counter-realtime'
import { useSelector } from 'react-redux'
import { Card, Button, ButtonGroup, Typography, Box } from '@material-ui/core'

const CounterRealtimeWithEffect = () => {
  const classes = useStyles()

  const total = useSelector(CounterRealtime.selectors.selectTotal)

  const [getTotal, increment, decrement, reset] = useEvent([
    CounterRealtime.eventBus.getTotalWithEffect,
    CounterRealtime.eventBus.incrementWithEffect,
    CounterRealtime.eventBus.decrementWithEffect,
    CounterRealtime.eventBus.resetWithEffect
  ])

  useEventsOnViewLoad(() => {
    getTotal.trigger()
  }, [getTotal])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <h3>Simple fetch Counter</h3>
        <p> open `src/pages/gamma-ray-http-like-counter-redux/index.js` to understand. </p>
        <p>
          In this example we use redux with GammaRay. The main difference from the first one
          is the burst listeners are redux actions.
        </p>

        <p> Blast the bursts and wait for the response in the burst event listeners. </p>

        <Box p={1}>
          <h3>Total:</h3>
          <Typography variant='h2'><span data-test='counter-result' data-value={total}>{total}</span></Typography>
        </Box>

        <ButtonGroup variant='contained' color='primary' aria-label='contained primary button group'>
          <Button
            children='+'
            variant='contained'
            disabled={increment.isWorking}
            data-test='counter-increment'
            onClick={() => increment.trigger()}
          />
          <Button
            children='-'
            variant='contained'
            disabled={decrement.isWorking}
            data-test='counter-decrement'
            onClick={() => decrement.trigger()}
          />
          <Button
            children='Reset'
            variant='contained'
            disabled={reset.isWorking}
            data-test='counter-reset'
            onClick={() => reset.trigger()}
          />
        </ButtonGroup>
      </Card>
    </div>
  )
}

export default CounterRealtimeWithEffect
