import { Table, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { EntriesSlice, InvoicesSlice } from '../../reducers'
import { useStyles } from './style'
import { Chart, LineSeries } from '@devexpress/dx-react-chart-material-ui'
import { NoteAltOutlined, Share } from '@mui/icons-material'

const InvoicesListEntries = ({ entry: { client, hours } }) => {
  const classes = useStyles()
  const projects = useSelector(EntriesSlice.selectors.selectProjects)
  const filteredEntries = useSelector(InvoicesSlice.selectors.selectFilteredEntries).filter(
    entry => entry.client.id === client.id
  )
  const groupEntriesByProject = projects.map(({ id }) => filteredEntries.filter(({ project }) => project.id === id).reduce((acc, curr) => ({ ...curr, hours: acc.hours + curr.hours }), { hours: 0 })).filter(({ hours }) => hours > 0)
  const lastThreeMonthsSumByMonth = useSelector(InvoicesSlice.selectors.selectLastThreeMonthsEntriesSumByMonth).map((entries, x) => ({
    x: 3 - x,
    y: entries.filter(
      entry => entry?.client?.id === client.id
    ).reduce((acc, curr) => acc + curr.hours, 0)
  }))
  const lastThreeMonthsGroupEntries = useSelector(InvoicesSlice.selectors.selectLastThreeMonthsGroupEntries)
  const average = (lastThreeMonthsGroupEntries.find(({ client: { id: clientId } }) => client.id === clientId)?.hours / 3).toFixed(2)
  const percentage = (((hours / average) * 100) - 100).toFixed(2)
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <TableContainer className={classes.entries}>
          <Table>
            {groupEntriesByProject.map((project, key) =>
              <TableRow key={key}>
                <TableCell>
                  <Typography className={classes.bold}>{project.project.name}</Typography>
                  <div className={classes.notes}>
                    <NoteAltOutlined fontSize='small' />
                    <Typography variant='body1'>Invoice Notes</Typography>
                  </div>
                </TableCell>
                <TableCell>
                  <Typography>Hours</Typography>
                  <Typography className={classes.green}>
                    {project.hours.toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>Status</Typography>
                  <Typography className={classes.green}>
                    Sent
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>Actions</Typography>
                  <div className={classes.actions}>
                    <NoteAltOutlined fontSize='small' />
                    <Share fontSize='small' />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>
        <div className={classes.average}>
          <div style={{ height: '300px', paddingLeft: '7px' }}>
            <Typography className={classes.bold}>Last 3 Months Average</Typography>
            <Typography className={classes.text}>{average || 'Not Available'}</Typography>
            <Typography className={classes.bold}>Total hours</Typography>
            <Typography className={classes.text}>{hours.toFixed(2)}</Typography>
            <Typography className={classes.bold}>Average</Typography>
            <Typography style={{ color: percentage > 0 ? '#6EC0A8' : 'red', justifyContent: 'center', display: 'flex' }}>{percentage}%</Typography>
            <div className={classes.text}>
              <Chart
                data={lastThreeMonthsSumByMonth}
                rotated
                height={100}
                width={100}
              >
                <LineSeries valueField='x' argumentField='y' color='#6EC0A8' />
              </Chart>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default InvoicesListEntries
