import { Box, Button, Checkbox, InputLabel, List, ListItem, Modal, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './style'
import { role as roleSlice } from '../../../reducers'

const CreateDeleteRoleModal = ({ open, setOpen }) => {
  const [role, setRole] = React.useState({ name: null })
  const [selectedRoles, setSelectedRoles] = React.useState([])
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const roles = useSelector(roleSlice.selectors.selectRoles)
  const classes = useStyles()
  const dispatcher = useDispatch()

  const saveRole = () => {
    dispatcher(roleSlice.actions.createRole(role))
    setValue('name', { target: { value: '' } })
  }

  const setValue = (key, e) => setRole(role => ({ ...role, [key]: e.target.value }))

  return (
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <Box className={classes.box}>
        <Typography align='center' variant='h6'>Roles</Typography>
        <List className={classes.list}>
          {roles.map(role => <ListItem key={role.id} className={classes.item} onClick={() => { setSelectedRoles(selectedRoles => selectedRoles.indexOf(role.id) >= 0 ? selectedRoles.filter(id => id !== role.id) : [...selectedRoles, role.id]) }}><Checkbox checked={selectedRoles.indexOf(role.id) >= 0} /> {role.name}</ListItem>)}
        </List>
        <div>
          <Typography align='center' variant='h6'>Create a new role</Typography>
          <InputLabel id='name' className={classes.label}>Name</InputLabel>
          <TextField id='name' value={role?.name} onChange={e => setValue('name', e)} fullWidth required />
        </div>
        <Button disabled={!role?.name?.length} onClick={saveRole} className={classes.button}>Create</Button>
        <Button disabled={!selectedRoles?.length} onClick={() => setOpenDeleteModal(true)} className={classes.button}>Delete</Button>
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          confirm={() => { dispatcher(roleSlice.actions.deleteRoleBatch(selectedRoles)); setSelectedRoles([]); setOpenDeleteModal(false) }}
          classes={classes}
          roles={selectedRoles?.map(id => roles.find(role => role.id === id)?.name)} />
      </Box>
    </Modal >
  )
}

const DeleteModal = ({ open, setOpen, confirm, roles, classes }) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box className={classes.box}>
      <Typography>Are you sure you want to delete <b>{roles?.join(', ')}</b> roles?</Typography>
      <div className={classes.buttons}>
        <Button onClick={confirm}>Delete</Button>
        <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
      </div>
    </Box>
  </Modal>
)
export default CreateDeleteRoleModal
