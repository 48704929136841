import name from './name'

const selectSliceData = state => state[name]
const selectClients = state => selectSliceData(state).clients
const selectIsWorking = state => selectSliceData(state).isWorking

export {
  selectClients,
  selectIsWorking
}
