import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const Inbound = createSlice({
  name,
  initialState: {
    filteredClients: [],
    startDate: null,
    endDate: null,
    filteredEntries: [],
    groupedEntriesByMonth: [],
    lastThreeMonthsEntries: [],
    lastThreeMonthsGroupEntries: [],
    lastThreeMonthsEntriesSumByMonth: [],
    filteredTasks: [],
    search: ''
  },
  reducers: {
    setFilteredClients: (state, { payload }) => {
      state.filteredClients = payload
    },
    setStartdate: (state, { payload }) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload
    },
    setFilteredEntries: (state, { payload }) => {
      state.filteredEntries = payload
    },
    setGroupedEntriesByMonth: (state, { payload }) => {
      state.groupedEntriesByMonth = payload
    },
    setLastThreeMonthsEntries: (state, { payload }) => {
      state.lastThreeMonthsEntries = payload
    },
    setLastThreeMonthsGroupEntries: (state, { payload }) => {
      state.lastThreeMonthsGroupEntries = payload
    },
    setLastThreeMonthsEntriesSumByMonth: (state, { payload }) => {
      state.lastThreeMonthsEntriesSumByMonth = payload
    },
    setFilteredTasks: (state, { payload }) => {
      state.filteredTasks = payload
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setFilteredClients,
  setStartdate,
  setEndDate,
  setFilteredEntries,
  setGroupedEntriesByMonth,
  setLastThreeMonthsEntries,
  setLastThreeMonthsGroupEntries,
  setLastThreeMonthsEntriesSumByMonth,
  setFilteredTasks,
  setSearch
} = Inbound.actions

// Reducer
export default Inbound.reducer
