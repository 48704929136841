import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bolder'
  },
  average: {
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
    height: '100%',
    flexDirection: 'column'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '10px',
    width: '180px'
  },
  green: {
    color: theme.palette.primary.main
  },
  notes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center'
  },
  cell: {
    width: '33%'
  },
  row: {
    display: 'flex'
  }
}))
