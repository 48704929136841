import { Box, Button, Collapse, Divider, FormControlLabel, InputLabel, MenuItem, Modal, Select, Switch, TextField, Typography } from '@material-ui/core'
import { Delete } from '@mui/icons-material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { invoice as invoiceSlice, project, invoiceItemCategory as invoiceItemCategorySlice } from '../../../reducers'
import { useStyles } from './style'

const init = [
  'client_id',
  'retainer_id',
  'estimate_id',
  'number',
  'purchase_order',
  'tax',
  'tax2',
  'discount',
  'subject',
  'notes',
  'currency',
  'issue_date',
  'due_date'
].reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {})
init.payment_term = 'custom'
init.line_items = []
init.line_items_import = { project_ids: [], time: { summary_type: undefined }, expenses: {} }

const paymentTerms = ['custom', 'upon receipt', 'net 15', 'net 30', 'net 45', 'net 60']

const currencies = [
  { name: 'BRL', symbol: 'R$' },
  { name: 'GBP', symbol: '£' },
  { name: 'USD', symbol: 'U$' }
]

const summary_types = [
  { name: 'Project', value: 'project' },
  { name: 'Task', value: 'task' },
  { name: 'People', value: 'people' },
  { name: 'Detailed', value: 'detailed' }
]

const CreateEditInvoiceModal = ({ edit, open, setOpen, invoiceToEdit }) => {
  const [invoice, setInvoice] = React.useState(init)
  const [invoiceId, setInvoiceId] = React.useState('')
  const [clientId, setClientId] = React.useState('')
  const [diff, setDiff] = React.useState(false)
  const [openList, setOpenList] = React.useState([])
  const projects = useSelector(project.selectors.selectProjects)
  const invoiceItemCategories = useSelector(invoiceItemCategorySlice.selectors.selectInvoiceItemCategories)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const saveInvoice = () => {
    if (edit) {
      dispatcher(invoiceSlice.actions.updateInvoice(invoiceId, { ...invoice, bill_by: invoice?.is_billable ? invoice?.bill_by : 'none', client_id: clientId }))
    } else {
      dispatcher(invoiceSlice.actions.createInvoice({ ...invoice, bill_by: invoice?.is_billable ? invoice?.bill_by : 'none', client_id: clientId }))
    }
    setInvoice({})
    setOpen(false)
  }

  React.useEffect(() => {
    dispatcher(project.actions.fetchProjects(clientId, { is_active: true }))
  }, [clientId, dispatcher])

  React.useEffect(() => {
    setClientId(invoiceToEdit?.client_id)
    if (edit) {
      setInvoiceId(invoiceToEdit?.id)
      const initialState = {}
      Object.keys(init).forEach(key => { initialState[key] = invoiceToEdit[key] ?? init[key] })
      setInvoice(initialState)
    }
  }, [edit, invoiceToEdit])

  React.useEffect(() => {
    const initialState = {}
    Object.keys(invoice).forEach(key => { initialState[key] = invoiceToEdit ? invoiceToEdit[key] : undefined })
    setDiff(JSON.stringify(initialState) !== JSON.stringify(invoice))
  }, [invoice, invoiceToEdit])

  const setValue = ({ key, subkey, subsubkey }, e) =>
    subsubkey ?
      setInvoice(invoice => ({ ...invoice, [key]: { ...invoice[key], [subkey]: { ...invoice[key][subkey], [subsubkey]: e.target.value } } }))
      : subkey ?
        setInvoice(invoice => ({ ...invoice, [key]: { ...invoice[key], [subkey]: e.target.value } }))
        : setInvoice(invoice => ({ ...invoice, [key]: e.target.value }))
  const addLineItem = () => {
    setInvoice(invoice => ({ ...invoice, line_items: [...invoice?.line_items, {}] }))
  }
  const removeLineItem = (index) => {
    setInvoice(invoice => ({ ...invoice, line_items: invoice?.line_items?.filter((_, i) => i !== index) }))
  }

  const updateLineItem = (index, key, e) => {
    setInvoice(invoice => ({ ...invoice, line_items: invoice?.line_items.map((item, i) => i === index ? { ...item, [key]: e.target.value } : item) }))
  }

  const openClose = index => setOpenList(openList => openList.indexOf(index) >= 0 ? openList.filter(id => id !== index) : [...openList, index])
  const isAllRequiredFilled = () => {
    const isAllKindFilled = invoice?.line_items?.reduce((prev, curr) => curr?.kind?.length > 0 && prev, true)
    return isAllKindFilled
  }
  const showInfo = (label, info) => (info?.toString().length > 0 && <Typography variant='body2' style={{ paddingLeft: '10px' }}><b>{label}</b>: {info.toString()}</Typography>)
  return (
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <Box className={classes.box}>
        <div>
          <Typography align='center' variant='h6'>{edit ? 'Edit' : 'New'} Invoice</Typography>
          <InputLabel id='retainer_id' className={classes.label}>Retainer Id</InputLabel>
          <TextField type='number' id='retainer_id' value={invoice?.retainer_id} onChange={e => setValue({ key: 'retainer_id' }, e)} fullWidth required />
          <InputLabel id='estimate_id' className={classes.label}>Estimate Id</InputLabel>
          <TextField type='number' id='estimate_id' value={invoice?.estimate_id} onChange={e => setValue({ key: 'estimate_id' }, e)} fullWidth />
          <InputLabel id='number' className={classes.label}>Number</InputLabel>
          <TextField id='number' value={invoice?.number} onChange={e => setValue({ key: 'number' }, e)} fullWidth />
          <InputLabel id='purchase_order' className={classes.label}>Purchase Order</InputLabel>
          <TextField id='purchase_order' value={invoice?.purchase_order} onChange={e => setValue({ key: 'purchase_order' }, e)} fullWidth />
          <InputLabel id='tax' className={classes.label}>Tax</InputLabel>
          <TextField type='number' id='tax' value={invoice?.tax} onChange={e => setValue({ key: 'tax' }, e)} fullWidth />
          <InputLabel id='tax2' className={classes.label}>Tax 2</InputLabel>
          <TextField type='number' id='tax2' value={invoice?.tax2} onChange={e => setValue({ key: 'tax2' }, e)} fullWidth />
          <InputLabel id='discount' className={classes.label}>Discount</InputLabel>
          <TextField type='number' id='discount' value={invoice?.discount} onChange={e => setValue({ key: 'discount' }, e)} fullWidth />
          <InputLabel id='subject' className={classes.label}>Subject</InputLabel>
          <TextField type='text' id='subject' value={invoice?.subject} onChange={e => setValue({ key: 'subject' }, e)} fullWidth />
          <InputLabel id='notes' className={classes.label}>Notes</InputLabel>
          <TextField type='text' multiline id='notes' value={invoice?.notes} onChange={e => setValue({ key: 'notes' }, e)} fullWidth />
          <InputLabel id='currency' className={classes.label}>Currency</InputLabel>
          <Select id='currency' fullWidth value={invoice?.currency} onChange={e => setValue({ key: 'currency' }, e)}>
            {currencies.map(({ name, symbol }) => <MenuItem key={name} value={name}>{symbol} {name}</MenuItem>)}
          </Select>
          <InputLabel id='issue_date' className={classes.label}>Issue Date</InputLabel>
          <TextField type='date' id='issue_date' value={invoice?.issue_date} onChange={e => setValue({ key: 'issue_date' }, e)} fullWidth />
          <InputLabel id='due_date' className={classes.label}>Due Date</InputLabel>
          <TextField type='date' id='due_date' value={invoice?.due_date} onChange={e => setValue({ key: 'due_date' }, e)} fullWidth />
          <InputLabel id='payment_term' className={classes.label}>Payment Term</InputLabel>
          <Select id='payment_term' fullWidth value={invoice?.payment_term} onChange={e => setValue({ key: 'payment_term' }, e)}>
            {paymentTerms?.map(pt => <MenuItem key={pt} value={pt}>{pt}</MenuItem>)}
          </Select>
          <div>
            {invoice?.line_items?.length > 0 && <InputLabel className={classes.label} style={{ paddingBottom: '5px' }}>Line Items</InputLabel>}
            {invoice?.line_items?.map((lineItem, index) =>
              <div key={index}>
                <Divider />
                <Button className={classes.detail} onClick={() => openClose(index)}>DETAIL LINE ITEM {index + 1}</Button>
                <Collapse in={openList.indexOf(index) >= 0}>
                  <>
                    <Delete onClick={() => removeLineItem(index)} className={classes.remove} />
                    <InputLabel id='project_id' className={classes.label}>Project</InputLabel>
                    <Select id='project_id' fullWidth value={invoice?.line_items?.find((_, i) => index === i)?.project_id} onChange={e => updateLineItem(index, 'project_id', e)}>
                      {projects?.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </Select>
                    <InputLabel id='kind' className={classes.label}>Kind*</InputLabel>
                    <Select id='kind' fullWidth value={invoice?.line_items?.find((_, i) => index === i)?.kind} onChange={e => updateLineItem(index, 'kind', e)}>
                      {invoiceItemCategories.map(({ name, id, use_as_service, use_as_expense }) => <MenuItem key={id} value={id}>{name} {showInfo('Use as Expense', use_as_expense)} {showInfo('Use as Service', use_as_service)}</MenuItem>)}
                    </Select>
                    <InputLabel id='description' className={classes.label}>Description</InputLabel>
                    <TextField multiline id='description' value={invoice?.line_items?.find((_, i) => index === i)?.description} onChange={e => updateLineItem(index, 'description', e)} fullWidth required />
                    <InputLabel id='quantity' className={classes.label}>Quantity</InputLabel>
                    <TextField type='number' id='quantity' value={invoice?.line_items?.find((_, i) => index === i)?.quantity} onChange={e => updateLineItem(index, 'quantity', e)} fullWidth required />
                    <InputLabel id='unit_price' className={classes.label}>Unit Price</InputLabel>
                    <TextField type='number' id='unit_price' value={invoice?.line_items?.find((_, i) => index === i)?.unit_price} onChange={e => updateLineItem(index, 'unit_price', e)} fullWidth required />
                    <FormControlLabel control={<Switch checked={invoice?.line_items?.find((_, i) => index === i)?.taxed} onChange={e => updateLineItem(index, 'taxed', { target: { value: !invoice?.line_items.find((_, i) => i === index)?.taxed } })} />} label='Taxed' />
                    <FormControlLabel control={<Switch checked={invoice?.line_items?.find((_, i) => index === i)?.taxed2} onChange={e => updateLineItem(index, 'taxed2', { target: { value: !invoice?.line_items.find((_, i) => i === index)?.taxed2 } })} />} label='Taxed2' />
                  </>
                </Collapse>
                <Divider />
              </div>)}
            <Button onClick={addLineItem} className={classes.add}>ADD LINE ITEM</Button>
          </div>
          <InputLabel id='line_items_import' className={classes.label}>Line Items Import</InputLabel>
          <InputLabel id='project_ids' className={classes.label}>Projects</InputLabel>
          <Select multiple id='project_ids' fullWidth value={invoice?.line_items_import?.project_ids} onChange={e => setValue({ key: 'line_items_import', subkey: 'project_ids' }, e)}>
            {projects?.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
          </Select>
          <InputLabel id='time' className={classes.label}>Time</InputLabel>
          <InputLabel id='summary_type' className={classes.label}>Summary Type</InputLabel>
          <Select id='summary_type' fullWidth value={invoice?.line_items_import?.time?.summary_type} onChange={e => setValue({ key: 'line_items_import', subkey: 'time', subsubkey: 'summary_type' }, e)}>
            {summary_types?.map(({ value, name }) => <MenuItem key={value} value={value}>{name}</MenuItem>)}
          </Select>
          <InputLabel id='from' className={classes.label}>From</InputLabel>
          <TextField type='date' id='from' value={invoice?.line_items_import?.time?.from} onChange={e => setValue({ subsubkey: 'from', subkey: 'time', key: 'line_items_import' }, e)} fullWidth />
          <InputLabel id='to' className={classes.label}>To</InputLabel>
          <TextField type='date' id='to' value={invoice?.line_items_import?.time?.to} onChange={e => setValue({ subsubkey: 'to', subkey: 'time', key: 'line_items_import' }, e)} fullWidth />
          <InputLabel id='expenses' className={classes.label}>Expenses</InputLabel>
          <InputLabel id='summary_type' className={classes.label}>Summary Type</InputLabel>
          <Select id='summary_type' fullWidth value={invoice?.line_items_import?.expenses?.summary_type} onChange={e => setValue({ key: 'line_items_import', subkey: 'expenses', subsubkey: 'summary_type' }, e)}>
            {summary_types?.map(({ value, name }) => <MenuItem key={value} value={value}>{name}</MenuItem>)}
          </Select>
          <InputLabel id='from' className={classes.label}>From</InputLabel>
          <TextField type='date' id='from' value={invoice?.line_items_import?.expenses?.from} onChange={e => setValue({ subsubkey: 'from', subkey: 'expenses', key: 'line_items_import' }, e)} fullWidth />
          <InputLabel id='to' className={classes.label}>To</InputLabel>
          <TextField type='date' id='to' value={invoice?.line_items_import?.expenses?.to} onChange={e => setValue({ subsubkey: 'to', subkey: 'expenses', key: 'line_items_import' }, e)} fullWidth />
          <FormControlLabel control={<Switch checked={invoice?.line_items_import?.expenses?.attach_receipts} onChange={e => setValue({ subsubkey: 'attach_receipts', subkey: 'expenses', key: 'line_items_import' }, { target: { value: !invoice?.line_items_import?.expenses?.attach_receipts } })} />} label='Attach Receipts' />
        </div>
        <Button disabled={edit ? !diff : !isAllRequiredFilled()} onClick={saveInvoice} className={classes.button}>{edit ? 'Save' : 'Create'}</Button>
      </Box>
    </Modal>
  )
}

export default CreateEditInvoiceModal
