import { createSlice } from '@reduxjs/toolkit'
import consts from '../../consts'
import { HarvestCloneApi } from '../../services/api'
import name from './name'
import { sortActives } from '../../utils'

export const invoiceSlice = createSlice({
  name,
  initialState: {
    invoices: [],
    isWorking: false
  },
  reducers: {
    setInvoices: (state, { payload }) => {
      state.invoices = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    }
  }
})

export const {
  setInvoices,
  setIsWorking
} = invoiceSlice.actions

export const fetchInvoices = query => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get('invoices', query)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setInvoices(result.data.sort(sortActives) || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const updateInvoice = (id, invoice) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.patch(`invoices/${id}`, invoice)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchInvoices())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const createInvoice = invoice => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.post('invoices', invoice)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchInvoices())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteInvoice = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.delete(`invoices/${id}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchInvoices())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export default invoiceSlice.reducer
