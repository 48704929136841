
import { createTheme, responsiveFontSizes } from '@material-ui/core'

export default function theme(options) {
  const theme = createTheme({
    palette: {
      type: options?.darkTheme ? 'dark' : 'light',
      primary: {
        main: '#6EC0A8'
      },
      secondary: {
        main: '#FFFFFF'
      },
      background: {
        main: '#303030'
      }
    },
    typography: {
      fontFamily: 'Poppins'
    },
    overrides: {
      MuiListItemText: {
        primary: {
          fontSize: '12px'
        }
      },
      MuiToolbar: {
        root: {
          backgroundColor: '#595959'
        }
      }
    }
  })

  return responsiveFontSizes(theme)
}
