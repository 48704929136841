import React, { useState, useEffect } from 'react'
import { useEvent, useConnectionContext } from '@emerald-works/react-event-bus-client'
import { Card, Button, Grid, Box, ButtonGroup } from '@material-ui/core'
import CodeSnippet from '../../components/code-snippet'

const DisconnectHandlingExample = () => {
  const context = useConnectionContext()
  const [seconds, setSeconds] = useState(10)
  const [connected, setConnected] = useState(context.isConnected)
  const [disconnectEvent, eventHasBeenTriggered] = useEvent([
    'disconnect',
    'Event Has Been Triggered'
  ])
  // with the increment/decrement event created we just need to write html and hook the events up.
  // When the user clicks on the `+` button: trigger event to increment and update the total
  // with the result and let react do the rest. The same for the `-` button.
  useEffect(() => {
    const oldStatus = connected
    setConnected(context.isConnected)
    if (oldStatus !== context.isConnected && !context.isConnected) {
      setSeconds(10)
    }
  }, [connected, context.isConnected])
  useEffect(() => {
    if (seconds > 0) {
      const remaining = seconds - 1
      setTimeout(() => {
        setSeconds(remaining)
      }, 1000)
    } else {
      if (!context.isConnected && !eventHasBeenTriggered.isWorking) {
        eventHasBeenTriggered.trigger()
      }
    }
  }, [seconds, eventHasBeenTriggered, context.isConnected])
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <h3>Disconnect Handling</h3>
        <p>
          This is an example to showcase how to handle socket disconnection.
        </p>
        <p>
          There is a reserved event named called{' '}
          <CodeSnippet>disconnect</CodeSnippet> that can help you to simulate
          this behavior.
        </p>
        <p>
          The socket will try to reconnect <b>anytime</b> a new event is{' '}
          <b>triggered</b>
        </p>
        <br />
        <Card>
          <Box p={2}>
            <h3>Not Blocking</h3>
            {context && !context.isConnected && (
              <h4>
                You are not connected, but that is fine, don't close your window
                and your data will be saved.
              </h4>
            )}
            <ButtonGroup
              variant='contained'
              color='primary'
              aria-label='contained primary button group'
            >
              <Button
                children='Disconnect'
                disabled={context && !context.isConnected}
                data-test='disconnect-handling-disconnect'
                onClick={() => disconnectEvent.trigger()}
              />
              <Button
                children='Any Event'
                disabled={eventHasBeenTriggered.isWorking}
                data-test='disconnect-handling-reconnect'
                onClick={() => eventHasBeenTriggered.trigger()}
              />
            </ButtonGroup>
          </Box>
        </Card>
        <br />
        <Card>
          <Box p={2}>
            <h3>Blocking</h3>
            {context && context.isConnected && (
              <ButtonGroup
                variant='contained'
                color='primary'
                aria-label='contained primary button group'
              >
                <Button
                  children='Disconnect'
                  disabled={context && !context.isConnected}
                  data-test='disconnect-handling-disconnect'
                  onClick={() => disconnectEvent.trigger()}
                />
                <Button
                  children='Any Event'
                  disabled={eventHasBeenTriggered.isWorking}
                  data-test='disconnect-handling-reconnect'
                  onClick={() => eventHasBeenTriggered.trigger()}
                />
              </ButtonGroup>
            )}
            {context && !context.isConnected && (
              <h4>
                You are not connected, please wait {seconds} seconds until
                you're reconnected.
                <Button
                  onClick={() => {
                    eventHasBeenTriggered.trigger()
                  }}
                >
                  reconnect manually
                </Button>
              </h4>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DisconnectHandlingExample
