import { Collapse, Table, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { AddSharp, Remove } from '@material-ui/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { InboundSlice } from '../../reducers'
import { InboundListEntries } from '../../components'
import { useStyles } from './style'

const InboundTable = () => {
  const [expand, setExpand] = React.useState(null)
  const classes = useStyles()
  const groupedEntriesByMonth = useSelector(InboundSlice.selectors.selectGroupedEntriesByMonth)

  return (
    <TableContainer>
      <Table>
        {groupedEntriesByMonth?.map((month, i) => month?.map((entry, j) =>
          <div key={`${i} ${j}`}>
            <TableRow key={`${i} ${j}`}>
              <TableCell className={classes.cell} style={{ borderBottom: expand === `${i} ${j}` ? 'none' : '' }}>
                <Typography variant='body1' className={classes.cellTitle}>
                  {entry.range.start} to {entry.range.end}
                </Typography>
                <Typography variant='body1' className={classes.green}>
                  {entry.client.name}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} style={{ borderBottom: expand === `${i} ${j}` ? 'none' : '' }}>
                <Typography variant='body1' className={classes.cellTitle}>
                  Status
                </Typography>
                <Typography variant='h6' className={classes.green}>
                  Received
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} style={{ borderBottom: expand === `${i} ${j}` ? 'none' : '' }}>
                <Typography variant='body1' className={classes.cellTitle}>
                  Hours
                </Typography>
                <Typography variant='h6' className={classes.green}>
                  {/* must multiply by users rate */}
                  {entry.hours.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell width='2px' style={{ borderBottom: expand === `${i} ${j}` ? 'none' : '' }}>
                {`${i} ${j}` === expand ? <Remove onClick={() => setExpand(null)} style={{ cursor: 'pointer' }} /> : <AddSharp onClick={() => setExpand(`${i} ${j}`)} style={{ cursor: 'pointer' }} />}
              </TableCell>
            </TableRow>
            <td colSpan='5'>
              <Collapse in={expand === `${i} ${j}`}>
                <InboundListEntries entry={entry} />
              </Collapse>
            </td>
          </div>
        ))}
      </Table>
    </TableContainer>
  )
}
export default InboundTable
