import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  generalInfo: {
    width: '100%',
    height: '100%',
    margin: '2px',
    padding: '10px',
    color: 'white'
  },
  green: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  exchanges: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  exchangeTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  exchangeItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '15%',
    paddingRight: '15%'
  }
}))
