import { createSlice } from '@reduxjs/toolkit'
import consts from '../../consts'
import { HarvestCloneApi } from '../../services/api'
import name from './name'
import { sortActives } from '../../utils'

export const clientSlice = createSlice({
  name,
  initialState: {
    clients: [],
    isWorking: false
  },
  reducers: {
    setClients: (state, { payload }) => {
      state.clients = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    }
  }
})

export const {
  setClients,
  setIsWorking
} = clientSlice.actions

export const fetchClients = () => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get('clients')
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setClients(result.data.sort(sortActives) || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const updateClient = (id, client) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.patch(`clients/${id}`, client)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchClients())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const createClient = client => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.post('clients', client)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchClients())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteClient = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.delete(`clients/${id}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchClients())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export default clientSlice.reducer
