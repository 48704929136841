import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateEditUserModal, Title, CreateDeleteRoleModal } from '../../../components'
import { role, user } from '../../../reducers'
import { useStyles } from './style'

const AdminUsers = () => {
  const [userToEdit, setUserToEdit] = React.useState({ name: '', currency: '', id: '', address: '', is_active: undefined })
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [userToDelete, setUserToDelete] = React.useState('')
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const users = useSelector(user.selectors.selectUsers)
  const roles = useSelector(role.selectors.selectRoles)
  const [openCreateEditUserModal, setOpenCreateEditUserModal] = React.useState(false)
  const [openRolesModal, setOpenRolesModal] = React.useState(false)
  const classes = useStyles()
  const dispatcher = useDispatch()

  const showInfo = (label, info) => (info?.toString().length > 0 && <Typography variant='body2' style={{ paddingRight: '10px' }}><b>{label}</b>: {info.toString()}</Typography>)

  const createRoleModalButton = () => <Button onClick={() => { setOpenRolesModal(true) }}>ROLES</Button>
  return (
    <div>
      <Title title='Users' buttonText='Create User' buttonAction={() => setOpenCreateEditUserModal(true)} extraComponent={createRoleModalButton} />
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            {users.map(user =>
              <TableRow className={classes.row} key={user.id}>
                <TableCell className={classes.cell}>
                  <div style={!user?.is_active ? { color: 'grey' } : {}}>
                    <div className={classes.title}>
                      <Typography variant='h6'>{user?.first_name} {user?.last_name}</Typography>
                      <Typography variant='body2' className={classes.id}>{user?.id}</Typography>
                    </div>
                    {showInfo('Email', user?.email)}
                    {showInfo('Timezone', user?.timezone)}
                    {showInfo('Has access to all future projects', user?.has_access_to_all_future_projects)}
                    {showInfo('Is Contractor', user?.is_contractor)}
                    {showInfo('Is Active', user?.is_active)}
                    {showInfo('Weekly Capacity', user?.weekly_capacity)}
                    {showInfo('Default Hourly Rate', user?.default_hourly_rate)}
                    {showInfo('Cost Rate', user?.cost_rate)}
                    {showInfo('Roles', user?.roles?.map(roleId => roles.find(({ id }) => id === roleId)?.name)?.join(', '))}
                    {showInfo('Access Roles', user?.access_roles?.join(', '))}
                  </div>
                  <div>
                    <Edit onClick={() => { setUserToEdit(user); setOpenEditModal(true) }} className={classes.icon} />
                    <Delete onClick={() => { setUserToDelete(user.id); setOpenDeleteModal(true) }} className={classes.icon} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateDeleteRoleModal open={openRolesModal} setOpen={setOpenRolesModal} />
      <CreateEditUserModal
        open={openCreateEditUserModal}
        setOpen={setOpenCreateEditUserModal}
      />
      <CreateEditUserModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        edit
        userToEdit={userToEdit}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirm={() => { dispatcher(user.actions.deleteUser(userToDelete)); setOpenDeleteModal(false) }}
        user={users.find(({ id }) => id === userToDelete)}
        classes={classes}
      />
    </div>
  )
}

const DeleteModal = ({ open, setOpen, confirm, user, classes }) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box className={classes.box}>
      <Typography>Are you sure you want to delete <b>{user?.first_name} {user?.last_name} - {user?.email}</b> user?</Typography>
      <div className={classes.buttons}>
        <Button onClick={confirm}>Delete</Button>
        <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
      </div>
    </Box>
  </Modal>
)

export default AdminUsers
