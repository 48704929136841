import { ArgumentAxis, Chart, LineSeries, ValueAxis } from '@devexpress/dx-react-chart-material-ui'
import { Card, Collapse, Checkbox, Typography, Button } from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EntriesSlice, WorkingHoursSlice } from '../../reducers'
import { useStyles } from '../workinghours-generalinfo/style'
import { AddSharp, Remove } from '@material-ui/icons'

const symbol = {
  BRL: 'R$',
  GBP: '£',
  USD: 'U$'
}

const WorkingHoursGeneralInfo = () => {
  const dispatcher = useDispatch()
  const classes = useStyles()
  const filteredEntries = useSelector(WorkingHoursSlice.selectors.selectFilteredEntries)
  const tasks = useSelector(EntriesSlice.selectors.selectTasks)
  const hours = filteredEntries.reduce((acc, curr) => acc + curr.hours, 0).toFixed(2)
  const availableCurrencies = Array.from(new Set(useSelector(EntriesSlice.selectors.selectClients).map(({ currency }) => currency)))
  const lastThreeMonthsEntriesSumByMonth = useSelector(WorkingHoursSlice.selectors.selectLastThreeMonthsEntriesSumByMonth)
  const lastThreeMonthsHoursSum = lastThreeMonthsEntriesSumByMonth.map((entries, subtract) => ({
    x: moment().subtract(subtract + 1, 'months').format('MMMM').slice(0, 3),
    y: entries.reduce((acc, curr) => acc + curr.hours, 0)
  })).reverse()
  const [expandFilters, setExpandFilters] = useState(false)
  const [localSelectedTasks, setLocalSelectedTasks] = useState([])

  // From where comes the rate per client info?
  const hoursPerCurrency = availableCurrencies.reduce((acc, curr) => {
    acc[curr] = filteredEntries.filter(({ client: { currency } }) => currency === curr).reduce((acc, curr) => acc + curr.hours, 0).toFixed(2)
    return acc
  }, {})

  const addOrRemoveFromSelectedTasks = (task) => {
    setLocalSelectedTasks(localSelectedTasks.find(t => t.id === task.id) === undefined ? [...localSelectedTasks, task] : localSelectedTasks.filter(t => t.id !== task.id))
  }

  const applySelectedTasks = () => {
    dispatcher(WorkingHoursSlice.actions.setFilteredTasks(localSelectedTasks))
  }

  return (
    <div className={classes.container}>
      <Card className={classes.generalInfo}>
        <div onClick={() => setExpandFilters(!expandFilters)} className={classes.filters}>
          <Typography variant='h6'>Filters</Typography>
          {expandFilters ? <Remove /> : <AddSharp />}
        </div>
        <Collapse in={expandFilters}>
          <Typography className={classes.bold}>Tasks</Typography>
          {tasks.map(task => <Typography key={task.id} className={classes.select}><Checkbox className={classes.checkbox} onClick={() => addOrRemoveFromSelectedTasks(task)} checked={localSelectedTasks.find(t => t.id === task.id) !== undefined} />{task.name}</Typography>)}
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button variant='contained' onClick={applySelectedTasks}>Apply</Button>
          </div>
        </Collapse>
      </Card>
      <Card className={classes.generalInfo}>
        <Typography className={classes.title} variant='h6'>Total</Typography>
        <Typography className={classes.hours} variant='h6'>{hours} hours</Typography>
        <Typography className={classes.title} variant='h6'>Acc</Typography>
        {availableCurrencies.map(currency =>
          <Typography key={currency} className={classes.hours} variant='h6'>
            {symbol[currency]} {hoursPerCurrency[currency]}
          </Typography>)}
      </Card>
      <Card className={classes.generalInfo}>
        <Chart
          data={lastThreeMonthsHoursSum}
          height={300}
          width='100%'
        >
          <ArgumentAxis />
          <ValueAxis showGrid={false} />
          <LineSeries valueField='y' argumentField='x' color='#6EC0A8' />
        </Chart>
      </Card>
      <Card className={classes.generalInfo}>
        <Typography>Insights</Typography>
        <Typography>God helps who early wakes.</Typography>
      </Card>
    </div>
  )
}
export default WorkingHoursGeneralInfo
