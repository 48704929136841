import { createSlice } from '@reduxjs/toolkit'
import name from './name'

export const localSlice = createSlice({
  name,
  initialState: {
    runningEntry: null,
    isWorking: true
  },
  reducers: {
    setRunningEntry: (state, { payload }) => {
      state.runningEntry = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    }
  }
})

export const {
  setRunningEntry,
  setIsWorking
} = localSlice.actions

export default localSlice.reducer
