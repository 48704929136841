import name from './name'

const selectSliceData = state => state[name]
const selectInvoiceItemCategories = state => selectSliceData(state).invoiceItemCategories
const selectIsWorking = state => selectSliceData(state).isWorking

export {
  selectInvoiceItemCategories,
  selectIsWorking
}
