import { Box, Button, Collapse, Divider, List, ListItem, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { Add, Delete, Edit, Remove } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateDeleteInvoiceItemCategoryModal, CreateEditInvoiceModal, Title } from '../../../components'
import { invoice as invoiceSlice, client, local, project } from '../../../reducers'
import { useStyles } from './style'

const AdminInvoices = () => {
  const [invoiceToEdit, setInvoiceToEdit] = React.useState({})
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [invoiceToDelete, setInvoiceToDelete] = React.useState('')
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const invoices = useSelector(invoiceSlice.selectors.selectInvoices)
  const [openCreateEditInvoiceModal, setOpenCreateEditInvoiceModal] = React.useState(false)
  const clients = useSelector(client.selectors.selectClients)
  const projects = useSelector(project.selectors.selectProjects)
  const [selectedClient, setSelectedClient] = React.useState('')
  const [openInvoiceItemCategoriesModal, setOpenInvoiceItemCategoriesModal] = React.useState(false)
  const working = useSelector(local.selectors.selectIsWorking)
  const [openList, setOpenList] = React.useState([])
  const classes = useStyles()
  const dispatcher = useDispatch()
  const showInfo = (label, info) => (info?.toString().length > 0 && <Typography variant='body2' style={{ paddingRight: '10px' }}><b>{label}</b>: {info?.toString()}</Typography>)
  React.useEffect(() => {
    if (selectedClient.length) {
      dispatcher(invoiceSlice.actions.fetchInvoices({ client_id: selectedClient }))
      dispatcher(project.actions.fetchProjects(selectedClient))
    }
  }, [dispatcher, selectedClient])
  const createInvoiceItemCategoriesModalButton = () => <Button onClick={() => { setOpenInvoiceItemCategoriesModal(true) }}>ITEM CATEGORIES</Button>
  const openClose = invoiceId => setOpenList(openList => openList.indexOf(invoiceId) >= 0 ? openList.filter(id => id !== invoiceId) : [...openList, invoiceId])
  return (
    <div>
      <Title title='Invoices' buttonText='Create Invoice' buttonAction={() => setOpenCreateEditInvoiceModal(true)} disableAction={!selectedClient.length} extraComponent={createInvoiceItemCategoriesModalButton} />
      <TableContainer>
        <Table className={classes.table}>
          <TableBody className={classes.body}>
            <Select className={classes.select} fullWidth id='client' value={selectedClient} onChange={e => setSelectedClient(e.target.value)}>
              {clients?.filter(client => client?.is_active).map(({ name, id }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
            </Select>
          </TableBody>
          <TableBody>
            {!working && selectedClient.length > 0 && invoices?.length === 0 && <Typography align='center' style={{ padding: '20px' }}>The selected client doesn't have projects.</Typography>}
            {invoices?.map(invoice =>
              <TableRow className={classes.row} key={invoice.id}>
                <TableCell className={classes.cell}>
                  <div className={classes.info}>
                    <div className={classes.title}>
                      <Typography variant='h6'>{invoice?.id}</Typography>
                    </div>
                    {showInfo('Issue Date', invoice?.issue_date)}
                    {showInfo('Due Date', invoice?.due_date)}
                    <Collapse in={openList.indexOf(invoice?.id) >= 0}>
                      {showInfo('Currency', invoice?.currency)}
                      {showInfo('Retainer Id', invoice?.retainer_id)}
                      {showInfo('Estimate Id', invoice?.estimate_id)}
                      {showInfo('Number', invoice?.number)}
                      {showInfo('Purchase Order', invoice?.purchase_order)}
                      {showInfo('Tax', invoice?.tax)}
                      {showInfo('Tax2', invoice?.tax2)}
                      {showInfo('Discount', invoice?.discount)}
                      {showInfo('Subject', invoice?.subject)}
                      {showInfo('Notes', invoice?.notes)}
                      {showInfo('Currency', invoice?.currency)}
                      {showInfo('Payment Term', invoice?.payment_term)}
                      <Typography variant='body2'><b>Line Items:</b></Typography>
                      {invoice?.line_items?.length > 0 ?
                        <List className={classes.list}>
                          {invoice?.line_items?.map(lineItem =>
                            <ListItem key={lineItem.id} className={classes.item}>
                              <div>
                                {showInfo('Project', projects?.find(({ id }) => lineItem?.project_id === id)?.name)}
                                {showInfo('Kind', lineItem?.kind)}
                                {showInfo('Description', lineItem?.description)}
                                {showInfo('Quantity', lineItem?.quantity)}
                                {showInfo('Unit Price', lineItem?.unit_price)}
                                {showInfo('Taxed', lineItem?.taxed)}
                                {showInfo('Taxed 2', lineItem?.taxed2)}
                              </div>
                              <Divider />
                            </ListItem>
                          )}
                        </List>
                        : <>This invoice does not contain line items.</>}
                      <Typography variant='body2'><b>Line Items Import:</b></Typography>
                      {invoice?.line_items_import ?
                        <div>
                          {showInfo('Projects', projects?.filter(({ id }) => invoice?.line_items_import?.project_ids?.indexOf(id) >= 0).map(({ name }) => name).join(', '))}
                          <Typography variant='body2'><b>Time:</b></Typography>
                          <div className={classes.subitem}>
                            {showInfo('Summary Type', invoice?.line_items_import?.time?.summary_type)}
                            {showInfo('From', invoice?.line_items_import?.time?.from)}
                            {showInfo('To', invoice?.line_items_import?.time?.to)}
                          </div>
                          <Typography variant='body2'><b>Expenses:</b></Typography>
                          <div className={classes.subitem}>
                            {showInfo('Summary Type', invoice?.line_items_import?.expenses?.summary_type)}
                            {showInfo('From', invoice?.line_items_import?.expenses?.from)}
                            {showInfo('To', invoice?.line_items_import?.expenses?.to)}
                            {showInfo('Attach Receipts', invoice?.line_items_import?.expenses?.attach_receipts)}
                          </div>
                        </div>
                        : <>This invoice does not contain line items.</>}
                    </Collapse>
                  </div>
                  <div className={classes.icons}>
                    {openList.indexOf(invoice?.id) < 0 ? <Add onClick={() => openClose(invoice?.id)} className={classes.icon} />
                      : <Remove onClick={() => openClose(invoice?.id)} className={classes.icon} />}
                    <Edit onClick={() => { setInvoiceToEdit(invoice); setOpenEditModal(true) }} className={classes.icon} />
                    <Delete onClick={() => { setInvoiceToDelete(invoice.id); setOpenDeleteModal(true) }} className={classes.icon} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateDeleteInvoiceItemCategoryModal open={openInvoiceItemCategoriesModal} setOpen={setOpenInvoiceItemCategoriesModal} />
      <CreateEditInvoiceModal
        open={openCreateEditInvoiceModal}
        setOpen={setOpenCreateEditInvoiceModal}
        invoiceToEdit={{ client_id: selectedClient }}
      />
      <CreateEditInvoiceModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        edit
        invoiceToEdit={invoiceToEdit}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirm={() => { dispatcher(invoiceSlice.actions.deleteInvoice(invoiceToDelete)); setOpenDeleteModal(false) }}
        invoice={invoices.find(({ id }) => id === invoiceToDelete)}
        classes={classes}
      />
    </div>
  )
}

const DeleteModal = ({ open, setOpen, confirm, invoice, classes }) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box className={classes.box}>
      <Typography>Are you sure you want to delete <b>{invoice?.name}</b> invoice?</Typography>
      <div className={classes.buttons}>
        <Button onClick={confirm}>Delete</Button>
        <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
      </div>
    </Box>
  </Modal>
)

export default AdminInvoices
