import { Button, Collapse, Table, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { WorkingHoursSlice } from '../../reducers'
import { useStyles } from './style'
import { Chart, LineSeries } from '@devexpress/dx-react-chart-material-ui'
import { PostAdd } from '@material-ui/icons'

const WorkingHoursListEntries = ({ entry: { project, client, user, hours } }) => {
  const classes = useStyles()
  const [expandTextField, setExpandTextField] = React.useState(false)
  const [localAttachment, setLocalAttachment] = React.useState('')
  const [attachment, setAttachment] = React.useState('')
  const filteredEntries = useSelector(WorkingHoursSlice.selectors.selectFilteredEntries).filter(
    entry => entry.project.id === project.id && entry.client.id === client.id && entry.user.id === user.id
  )
  const lastThreeMonthsSumByMonth = useSelector(WorkingHoursSlice.selectors.selectLastThreeMonthsEntriesSumByMonth).map((entries, x) => ({
    x: 3 - x,
    y: entries.filter(
      entry => entry?.project?.id === project.id && entry?.client?.id === client.id && entry?.user?.id === user.id
    ).reduce((acc, curr) => acc + curr.hours, 0)
  }))
  const lastThreeMonthsGroupEntries = useSelector(WorkingHoursSlice.selectors.selectLastThreeMonthsGroupEntries)
  const average = (lastThreeMonthsGroupEntries.find(({ user: { id: userId }, project: { id: projectId }, client: { id: clientId } }) => project.id === projectId && user.id === userId && client.id === clientId)?.hours / 3).toFixed(2)
  const percentage = (((hours / average) * 100) - 100).toFixed(2)
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <TableContainer className={classes.entries}>
          <Table>
            {filteredEntries.map((entry, key) =>
              <TableRow key={key}>
                <TableCell>
                  <Typography className={classes.bold}>{entry.task.name}</Typography>
                  <Typography>{entry.notes}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Hours</Typography>
                  <Typography className={classes.green}>
                    {entry.hours}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>
        <div className={classes.average}>
          <div style={{ height: '300px', paddingLeft: '7px' }}>
            <Typography style={{ justifyContent: 'center', display: 'flex' }} className={classes.bold}>Last 3 Months Average</Typography>
            <Typography style={{ justifyContent: 'center', display: 'flex' }}>{average || 'Not Available'}</Typography>
            <Typography style={{ justifyContent: 'center', display: 'flex' }} className={classes.bold}>Total hours</Typography>
            <Typography style={{ justifyContent: 'center', display: 'flex' }}>{hours.toFixed(2)}</Typography>
            <Typography style={{ justifyContent: 'center', display: 'flex' }} className={classes.bold}>Average</Typography>
            <Typography style={{ color: percentage > 0 ? '#6EC0A8' : 'red', justifyContent: 'center', display: 'flex' }}>{percentage}%</Typography>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Chart
                data={lastThreeMonthsSumByMonth}
                rotated
                height={100}
                width={100}
              >
                <LineSeries valueField='x' argumentField='y' color='#6EC0A8' />
              </Chart>
            </div>
          </div>
          <div className={classes.attach} onClick={() => setExpandTextField(!expandTextField)}>
            <PostAdd />
            <Typography>Attach</Typography>
          </div>
        </div>
      </div>
      <Collapse in={expandTextField}>
        <TextField placeholder='Type your notes here' className={classes.attachTextField} value={localAttachment} onChange={({ target: { value } }) => setLocalAttachment(value)} multiline variant='filled' fullWidth />
        <div className={classes.buttons}>
          <Button variant='contained' onClick={() => { setAttachment(localAttachment); setExpandTextField(false) }}>Save</Button>
          <Button variant='contained' onClick={() => { setExpandTextField(false); setLocalAttachment(attachment) }}>Cancel</Button>
        </div>
      </Collapse>
    </>
  )
}
export default WorkingHoursListEntries
