import { makeStyles } from '@material-ui/core/styles'
const drawerWidth = 100

export const useStyles = makeStyles(theme => ({
  drawerWithPage: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    paddingTop: '60px',
    width: drawerWidth
  },
  selected: {
    backgroundColor: '#515151'
  }
}))
