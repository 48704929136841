import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  pageTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px'
  }
}))
