import clients from './clients'
import projects from './projects'
import collaborators from './collaborators'
import assignes from './assignes'
import users from './users'
import tasks from './tasks'
import invoices from './invoices'
export default {
  clients,
  projects,
  collaborators,
  assignes,
  users,
  tasks,
  invoices
}
