import React from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useStyles } from './style'
import CounterRealtimeSlice from '../../reducers/counter-realtime'
import { useSelector } from 'react-redux'
import { Card, Button, Box, Typography, ButtonGroup } from '@material-ui/core'

const CounterRealtime = () => {
  const classes = useStyles()

  const total = useSelector(CounterRealtimeSlice.selectors.selectTotal)

  const [getTotal, increment, decrement, reset] = useEvent([
    CounterRealtimeSlice.eventBus.getTotal,
    CounterRealtimeSlice.eventBus.increment,
    CounterRealtimeSlice.eventBus.decrement,
    CounterRealtimeSlice.eventBus.reset
  ])

  useEventsOnViewLoad(() => {
    getTotal.trigger()
  }, [getTotal])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <h3>Simple fetch Counter</h3>
        <p> open `src/pages/gamma-ray-realtime-1/index.js` to understand. </p>
        <p>
          Realtime counter. Open a second tab and update the counter to check the realtime updates.
        </p>

        <Box p={1}>
          <h3>Total:</h3>
          <Typography variant='h2'><span data-test='counter-result' data-value={total}>{total}</span></Typography>
        </Box>

        <ButtonGroup variant='contained' color='primary' aria-label='contained primary button group'>
          <Button
            children='+'
            variant='contained'
            disabled={increment.isWorking}
            data-test='counter-increment'
            onClick={() => increment.trigger()}
          />
          <Button
            children='-'
            variant='contained'
            disabled={decrement.isWorking}
            data-test='counter-decrement'
            onClick={() => decrement.trigger()}
          />
          <Button
            children='Reset'
            variant='contained'
            disabled={reset.isWorking}
            data-test='counter-reset'
            onClick={() => reset.trigger()}
          />
        </ButtonGroup>
      </Card>
    </div>
  )
}

export default CounterRealtime
