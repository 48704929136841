import { Box, Button, Checkbox, FormControlLabel, InputLabel, List, ListItem, Modal, Switch, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './style'
import { invoiceItemCategory as invoiceItemCategorySlice } from '../../../reducers'

const CreateDeleteInvoiceItemCategoryModal = ({ open, setOpen }) => {
  const [invoiceItemCategory, setInvoiceItemCategory] = React.useState({ name: null })
  const [selectedInvoiceItemCategories, setSelectedInvoiceItemCategories] = React.useState([])
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const invoiceItemCategories = useSelector(invoiceItemCategorySlice.selectors.selectInvoiceItemCategories)
  const classes = useStyles()
  const dispatcher = useDispatch()

  const saveInvoiceItemCategory = () => {
    dispatcher(invoiceItemCategorySlice.actions.createInvoiceItemCategory(invoiceItemCategory))
    setValue('name', { target: { value: '' } })
    setValue('use_as_service', { target: { value: undefined } })
    setValue('use_as_expense', { target: { value: undefined } })
  }

  const setValue = (key, e) => setInvoiceItemCategory(invoiceItemCategory => ({ ...invoiceItemCategory, [key]: e.target.value }))
  const showInfo = (label, info) => (info?.toString().length > 0 && <Typography variant='body2' style={{ paddingLeft: '10px' }}><b>{label}</b>: {info.toString()}</Typography>)
  return (
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <Box className={classes.box}>
        <Typography align='center' variant='h6'>Invoice Item Categories</Typography>
        <List className={classes.list}>
          {invoiceItemCategories.map(invoiceItemCategory => <ListItem key={invoiceItemCategory.id} className={classes.item} onClick={() => { setSelectedInvoiceItemCategories(selectedInvoiceItemCategories => selectedInvoiceItemCategories.indexOf(invoiceItemCategory.id) >= 0 ? selectedInvoiceItemCategories.filter(id => id !== invoiceItemCategory.id) : [...selectedInvoiceItemCategories, invoiceItemCategory.id]) }}><Checkbox checked={selectedInvoiceItemCategories.indexOf(invoiceItemCategory.id) >= 0} /> {invoiceItemCategory.name} <div>{showInfo('Use as Expense', invoiceItemCategory?.use_as_expense)} {showInfo('Use as Service', invoiceItemCategory?.use_as_service)}</div></ListItem>)}
        </List>
        <div>
          <Typography align='center' variant='h6'>Create a new category</Typography>
          <InputLabel id='name' className={classes.label}>Name</InputLabel>
          <TextField id='name' value={invoiceItemCategory?.name} onChange={e => setValue('name', e)} fullWidth required />
          <FormControlLabel control={<Switch checked={invoiceItemCategory?.use_as_service} onChange={e => setValue('use_as_service', { target: { value: !invoiceItemCategory?.use_as_service } })} />} label='Use as service' />
          <FormControlLabel control={<Switch checked={invoiceItemCategory?.use_as_expense} onChange={e => setValue('use_as_expense', { target: { value: !invoiceItemCategory?.use_as_expense } })} />} label='Use as expense' />
        </div>
        <Button disabled={!invoiceItemCategory?.name?.length} onClick={saveInvoiceItemCategory} className={classes.button}>Create</Button>
        <Button disabled={!selectedInvoiceItemCategories?.length} onClick={() => setOpenDeleteModal(true)} className={classes.button}>Delete</Button>
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          confirm={() => { dispatcher(invoiceItemCategorySlice.actions.deleteInvoiceItemCategoryBatch(selectedInvoiceItemCategories)); setSelectedInvoiceItemCategories([]); setOpenDeleteModal(false) }}
          classes={classes}
          invoiceItemCategories={selectedInvoiceItemCategories?.map(id => invoiceItemCategories.find(invoiceItemCategory => invoiceItemCategory.id === id)?.name)}
        />
      </Box>
    </Modal>
  )
}

const DeleteModal = ({ open, setOpen, confirm, invoiceItemCategories, classes }) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box className={classes.box}>
      <Typography>Are you sure you want to delete <b>{invoiceItemCategories?.join(', ')}</b> invoice item categories?</Typography>
      <div className={classes.buttons}>
        <Button onClick={confirm}>Delete</Button>
        <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
      </div>
    </Box>
  </Modal>
)
export default CreateDeleteInvoiceItemCategoryModal
