import name from './name'

const selectSliceData = state => state[name]
const selectUsers = state => selectSliceData(state).users
const selectIsWorking = state => selectSliceData(state).isWorking

export {
  selectUsers,
  selectIsWorking
}
