import React from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useStyles } from './style'
import CounterSlice from '../../reducers/counter-example'
import { useSelector } from 'react-redux'
import { Card, Button, Box, Typography, ButtonGroup } from '@material-ui/core'

const CounterRequestResponseWithReduxExample = () => {
  const classes = useStyles()

  /*
    For this example we're introducing Redux. Redux is a state management for frontend
    applications. EventBus has a integration with Redux. This is: you can pass redux
    actions as listeners to EventBus events lifecycle hooks.
    In this example the total is coming from the Counter slice. And the event definition
    was removed from the component and created as part of the Redux slice.
    Go to `src/reducers/counter-example/event-bus.js` to check how it works.
  */

  /*
    Bear in mind that redux doesn't trigger anything to the backend. This was a pattern in
    the old version of this SDK. We've broken this pattern in favor of two thing:
    Calling the backend is one thing and updating your frontend state through redux is another thing.
    You'll notice in this example that the backend communication is handle by the EventBus. You
    trigger an event. This event is then sent to the backend that handles it and send back a response.
    The event listeners are fired and it do stuff with the backend response for that event. What happens
    is that you as a developer can set a redux action as the event listener creating the bind between
    the event bus and redux. The full follow would be:
      1 - create a event with a redux action as listener
      2 - trigger the event normally. It will be fired to backend
      3 - backend will process your event and send back a response
      4 - a event listener is trigger based on the response (success? error?) and because you've
          set (let's say) the onSuccess hook to be a redux action the event bus will fire the redux action
          in the redux context in case of a success response.
      5 - your redux action is fired with the right payload. it updates your redux state
      6 - React will react to the redux state change and your UI you'll be updated.
  */

  const total = useSelector(CounterSlice.selectors.selectTotal)

  const [getTotal, increment, decrement, reset] = useEvent([
    CounterSlice.eventBus.getTotal,
    CounterSlice.eventBus.increment,
    CounterSlice.eventBus.decrement,
    CounterSlice.eventBus.reset
  ])

  useEventsOnViewLoad(() => {
    increment.registerAdhocOnSuccessListener(() => {
      console.log(
        `
          This is adhoc onSuccess listerner for increment event. 
          This is useful when you want to run any code that
          needs to be in react context in some moment of the event lifecycle.
          Register the listerner in the react context.
          e.g.: navigate on success.
        `
      )
    })
    getTotal.trigger()
  }, [getTotal, increment])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <h3>Simple fetch Counter</h3>
        <p> open `src/pages/counter-request-response-with-redux-example/index.js` to understand. </p>
        <p>
          In this example we use redux with EventBus events. The main difference from the first one
          is the event listeners are redux actions.
        </p>

        <p> Trigger the event and wait for the response in the event listeners hooks. </p>

        <p> You can check actions being fired in the redux context with Redux DevTool
          <a href='https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd' target='__blank'>
            Redux DevTool for chrome
          </a>
        </p>

        <p>
          A good exercise is to check the messages in the socket and as response to it based on what you've setup in the event listeners:
          check the redux action being fired.
        </p>

        <Box p={1}>
          <h3>Total:</h3>
          <Typography variant='h2'><span data-test='counter-result' data-value={total}>{total}</span></Typography>
        </Box>

        <ButtonGroup variant='contained' color='primary' aria-label='contained primary button group'>
          <Button
            children='+'
            variant='contained'
            disabled={increment.isWorking}
            data-test='counter-increment'
            onClick={() => increment.trigger()}
          />
          <Button
            children='-'
            variant='contained'
            disabled={decrement.isWorking}
            data-test='counter-decrement'
            onClick={() => decrement.trigger()}
          />
          <Button
            children='Reset'
            variant='contained'
            disabled={reset.isWorking}
            data-test='counter-reset'
            onClick={() => reset.trigger()}
          />
        </ButtonGroup>
      </Card>
    </div>
  )
}

export default CounterRequestResponseWithReduxExample
