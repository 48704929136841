import React from 'react'
import { InboundFilter, InboundTable } from '../../components'
import InboundGeneralInfo from '../../components/inbound-generalinfo'
import { useStyles } from './style'

const Inbound = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <InboundFilter />
        <div style={{ display: 'flex', justifyItems: 'space-between' }}>
          <InboundTable />
          <InboundGeneralInfo />
        </div>
      </div>
    </div>
  )
}

export default Inbound
