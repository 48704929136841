import name from './name'

const selectSliceData = state => state[name]
const selectTasks = state => selectSliceData(state).tasks
const selectIsWorking = state => selectSliceData(state).isWorking

export {
  selectTasks,
  selectIsWorking
}
