import { Box, Button, FormControlLabel, InputLabel, MenuItem, Modal, Select, Switch, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './style'
import { user as userSlice, role as roleSlice } from '../../../reducers'

const access_roles = [
  { name: 'Administrator', value: 'administrator' },
  { name: 'Manager', value: 'manager' },
  { name: 'Member', value: 'member' },
  { name: 'Project Creator', value: 'project_creator' },
  { name: 'Billable Rates Manager', value: 'billable_rates_manager' },
  { name: 'Managed Projects Invoice Drafter', value: 'managed_projects_invoice_drafter' },
  { name: 'Managed Projects Invoice Manager', value: 'managed_projects_invoice_manager' },
  { name: 'Client and Task Manager', value: 'client_and_task_manager' },
  { name: 'Time and Expenses Manager', value: 'time_and_expenses_manager' },
  { name: 'Estimates Manager', value: 'estimates_manager' }
]

const init = [
  'first_name',
  'last_name',
  'email',
  'timezone',
  'has_access_to_all_future_projects',
  'is_contractor',
  'is_active',
  'weekly_capacity',
  'default_hourly_rate',
  'cost_rate',
  'roles'
].reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {})
init.access_roles = []
init.roles = []
const CreateEditUserModal = ({ edit, open, setOpen, userToEdit = {} }) => {
  const [user, setUser] = React.useState(init)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const [diff, setDiff] = React.useState(false)
  const roles = useSelector(roleSlice.selectors.selectRoles)

  const saveUser = () => {
    // this will send only the list with roles to add and to remove.
    const rolesToAdd = user?.roles?.filter(role => userToEdit?.roles?.indexOf(role) < 0) || []
    const rolesToRemove = userToEdit?.roles?.filter(role => user?.roles?.indexOf(role) < 0) || []
    const rolesList = [...rolesToAdd, ...rolesToRemove]
    if (edit) {
      dispatcher(userSlice.actions.updateUser(userToEdit.id, user, rolesList))
    } else {
      dispatcher(userSlice.actions.createUser(user, rolesList))
    }
    setOpen(false)
  }

  React.useEffect(() => {
    if (edit) {
      const initialState = {}
      Object.keys(init).forEach(key => { initialState[key] = userToEdit[key] ?? init[key] })
      setUser(initialState)
    }
  }, [edit, userToEdit])

  React.useEffect(() => {
    const initialState = {}
    Object.keys(init).forEach(key => { initialState[key] = userToEdit[key] ?? init[key] })
    setDiff(JSON.stringify(initialState) !== JSON.stringify(user))
  }, [user, userToEdit])

  const setValue = (key, e) => setUser(client => ({ ...client, [key]: e.target.value }))

  return (
    <Modal open={open} onClose={() => { setOpen(false) }}>
      <Box className={classes.box}>
        <Typography align='center' variant='h6'>{edit ? 'Edit' : 'New'} User</Typography>
        <div>
          <InputLabel id='first_name' className={classes.label}>Firt Name</InputLabel>
          <TextField id='first_name' value={user?.first_name} onChange={e => setValue('first_name', e)} fullWidth required />
          <InputLabel id='last_name' className={classes.label}>Last Name</InputLabel>
          <TextField id='last_name' value={user?.last_name} onChange={e => setValue('last_name', e)} fullWidth required />
          <InputLabel id='email' className={classes.label}>Email</InputLabel>
          <TextField id='email' value={user?.email} onChange={e => setValue('email', e)} fullWidth required />
          <InputLabel id='timezone' className={classes.label}>Timezone</InputLabel>
          <TextField id='timezone' value={user?.timezone} onChange={e => setValue('timezone', e)} fullWidth required />
          <FormControlLabel control={<Switch checked={Boolean(user?.has_access_to_all_future_projects)} onChange={e => setValue('has_access_to_all_future_projects', { target: { value: !user?.has_access_to_all_future_projects } })} />} label='Has access to all future projects' />
          <FormControlLabel control={<Switch checked={Boolean(user?.is_contractor)} onChange={e => setValue('is_contractor', { target: { value: !user?.is_contractor } })} />} label='Is contractor' />
          <FormControlLabel control={<Switch checked={Boolean(user?.is_active)} onChange={e => setValue('is_active', { target: { value: !user?.is_active } })} />} label='Is Active' />
          <InputLabel id='weekly_capacity' className={classes.label}>Weekly Capacity</InputLabel>
          <TextField type='number' id='weekly_capacity' fullWidth value={user?.weekly_capacity} onChange={e => setValue('weekly_capacity', e)} />
          <InputLabel id='default_hourly_rate' className={classes.label}>Default Hourly Rate</InputLabel>
          <TextField type='number' id='default_hourly_rate' fullWidth value={user?.default_hourly_rate} onChange={e => setValue('default_hourly_rate', e)} />
          <InputLabel id='cost_rate' className={classes.label}>Cost Rate</InputLabel>
          <TextField type='number' id='cost_rate' fullWidth value={user?.cost_rate} onChange={e => setValue('cost_rate', e)} />
          <InputLabel id='roles' className={classes.label}>Roles</InputLabel>
          <Select id='roles' fullWidth multiple value={user?.roles} onChange={e => setValue('roles', e)}>
            {roles?.map(({ name, id }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
          </Select>
          <InputLabel id='access_roles' className={classes.label}>Access Roles</InputLabel>
          <Select id='access_roles' fullWidth multiple value={user?.access_roles} onChange={e => setValue('access_roles', e)}>
            {access_roles.map(({ name, value }) => <MenuItem key={value} value={value}>{name}</MenuItem>)}
          </Select>
        </div>
        <Button disabled={!diff} onClick={saveUser} className={classes.button}>{edit ? 'Save' : 'Create'}</Button>
      </Box>
    </Modal>
  )
}

export default CreateEditUserModal
