import { ArgumentAxis, Chart, LineSeries, ValueAxis } from '@devexpress/dx-react-chart-material-ui'
import { Card, Collapse, Typography } from '@material-ui/core'
import { AddSharp, Remove } from '@mui/icons-material'
import React from 'react'
import { useStyles } from './style'
const InboundExchangeChart = ({ title, data, total, average, symbol, exchanges }) => {
  const [expand, setExpand] = React.useState(false)
  const classes = useStyles()
  return (
    <Card className={classes.generalInfo}>
      <Typography variant='h6'>{title}</Typography>
      <Typography variant='h5' className={classes.green}>{symbol} {total}</Typography>
      <Chart
        data={data}
        height={300}
        width='100%'
      >
        <ArgumentAxis />
        <ValueAxis showGrid={false} />
        <LineSeries valueField='y' argumentField='x' color='#6EC0A8' />
      </Chart>
      <Typography variant='h6'>Average: <Typography variant='h5' className={classes.green}>R$ {average}</Typography></Typography>
      <Typography className={classes.exchanges} onClick={() => setExpand(!expand)}>
                See exchanges done
        {expand ? <Remove /> : <AddSharp />}
      </Typography>
      <Collapse in={expand}>
        <Typography className={classes.exchangeTitle}><div>Date</div><div>Amount</div></Typography>
        {exchanges.map(({ date, amount, symbol }, i) =>
          <Typography key={i} className={classes.exchangeItem}><div>{date}</div><div>{symbol} {amount}</div></Typography>
        )}
      </Collapse>

    </Card>
  )
}

export default InboundExchangeChart
