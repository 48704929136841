import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const Workinghours = createSlice({
  name,
  initialState: {
    filteredProjects: [],
    filteredCollaborators: [],
    filteredClients: [],
    startDate: null,
    endDate: null,
    filteredEntries: [],
    groupedEntries: [],
    lastThreeMonthsEntries: [],
    lastThreeMonthsGroupEntries: [],
    lastThreeMonthsEntriesSumByMonth: [],
    filteredTasks: [],
    search: ''
  },
  reducers: {
    setFilteredCollaborators: (state, { payload }) => {
      state.filteredCollaborators = payload
    },
    setFilteredProjects: (state, { payload }) => {
      state.filteredProjects = payload
    },
    setFilteredClients: (state, { payload }) => {
      state.filteredClients = payload
    },
    setStartdate: (state, { payload }) => {
      state.startDate = payload
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload
    },
    setFilteredEntries: (state, { payload }) => {
      state.filteredEntries = payload
    },
    setGroupedEntries: (state, { payload }) => {
      state.groupedEntries = payload
    },
    setLastThreeMonthsEntries: (state, { payload }) => {
      state.lastThreeMonthsEntries = payload
    },
    setLastThreeMonthsGroupEntries: (state, { payload }) => {
      state.lastThreeMonthsGroupEntries = payload
    },
    setLastThreeMonthsEntriesSumByMonth: (state, { payload }) => {
      state.lastThreeMonthsEntriesSumByMonth = payload
    },
    setFilteredTasks: (state, { payload }) => {
      state.filteredTasks = payload
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setFilteredProjects,
  setFilteredCollaborators,
  setFilteredClients,
  setStartdate,
  setEndDate,
  setFilteredEntries,
  setGroupedEntries,
  setLastThreeMonthsEntries,
  setLastThreeMonthsGroupEntries,
  setLastThreeMonthsEntriesSumByMonth,
  setFilteredTasks,
  setSearch
} = Workinghours.actions

// Reducer
export default Workinghours.reducer
