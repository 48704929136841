import { Box, Button, Collapse, FormControlLabel, InputLabel, List, ListItem, MenuItem, Modal, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core'
import { Radio, Checkbox } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateEditClientModal, Title } from '../../../components'
import { client, local, project, user, task } from '../../../reducers'
import { useStyles } from './style'

const AdminAssignes = () => {
  const [type, setType] = React.useState('user')
  const [selectedClient, setSelectedClient] = React.useState('')
  const [selectedProject, setSelectedProject] = React.useState('')
  const [openReviewModal, setOpenReviewModal] = React.useState(false)
  const clients = useSelector(client.selectors.selectClients)
  const tasks = useSelector(task.selectors.selectTasks)
  const users = useSelector(user.selectors.selectUsers)
  const projects = useSelector(project.selectors.selectProjects)
  const [openCreateEditClientModal, setOpenCreateEditClientModal] = React.useState(false)
  const working = useSelector(local.selectors.selectIsWorking)
  const classes = useStyles()
  const dispatcher = useDispatch()
  const [rightSelect, setRightSelect] = React.useState([])
  const userAssignments = useSelector(project.selectors.selectUserAssignments)
  const taskAssignments = useSelector(project.selectors.selectTaskAssignments)
  const [toRemove, setToRemove] = React.useState([])
  const [toAdd, setToAdd] = React.useState([])
  const [toUpdate, setToUpdate] = React.useState([])
  const [openList, setOpenList] = React.useState([])
  const [assignments, setAssignments] = React.useState([])

  React.useEffect(() => {
    if (clients.find(client => client.id === selectedClient)) {
      dispatcher(project.actions.fetchProjects(selectedClient, { is_active: true }))
    } else {
      dispatcher(project.actions.setProjects([]))
    }
  }, [selectedClient, clients, dispatcher])

  React.useEffect(() => {
    if (selectedProject.length > 0) {
      switch (type) {
        case 'user':
          dispatcher(project.actions.getUserAssignments(selectedProject))
          break
        case 'task':
          dispatcher(project.actions.getTaskAssignments(selectedProject))
          break
        default:
      }
    }
  }, [selectedProject, type, dispatcher])

  React.useEffect(() => {
    switch (type) {
      case 'user':
        setRightSelect(userAssignments?.map(({ user_id }) => user_id))
        setAssignments(userAssignments)
        break
      case 'task':
        setRightSelect(taskAssignments?.map(({ task_id }) => task_id))
        setAssignments(taskAssignments)
        break
      default:
    }
  }, [userAssignments, taskAssignments, type, dispatcher])

  React.useEffect(() => {
    switch (type) {
      case 'user':
        setToRemove(userAssignments.filter(select => rightSelect.indexOf(select.user_id) < 0).map(({ user_id }) => user_id))
        setToAdd(rightSelect.filter(select => userAssignments.map(({ user_id }) => user_id).indexOf(select) < 0))
        setToUpdate(userAssignments.filter(select => JSON.stringify(select) !== JSON.stringify(assignments.find(({ user_id }) => user_id === select?.user_id)) && assignments?.find(({ user_id }) => user_id === select.user_id)))
        break
      case 'task':
        setToRemove(taskAssignments.filter(select => rightSelect.indexOf(select.task_id) < 0).map(({ task_id }) => task_id))
        setToAdd(rightSelect.filter(select => taskAssignments.map(({ task_id }) => task_id).indexOf(select) < 0))
        setToUpdate(taskAssignments.filter(select => JSON.stringify(select) !== JSON.stringify(assignments.find(({ task_id }) => task_id === select?.task_id)) && assignments?.find(({ task_id }) => task_id === select.task_id)))
        break
      default:
    }
  }, [assignments, rightSelect, userAssignments, type, taskAssignments])

  const openClose = projectId => setOpenList(openList => openList.indexOf(projectId) >= 0 ? openList.filter(id => id !== projectId) : [...openList, projectId])

  const updateUserAssignments = (...params) => updateAssignments('user_id', ...params)
  const updateTaskAssignments = (...params) => updateAssignments('task_id', ...params)

  const updateAssignments = (idName, id, key, e) => {
    const found = assignments.find(a => a[idName] === id)
    const assignment = { ...found ?? { [idName]: id } }
    assignment[key] = e.target.value
    setAssignments(assignments => found ? assignments.map(a => a[idName] === id ? assignment : a) : [...assignments, assignment])
  }
  return (
    <div>
      <Title title='Assignes' />
      <>
        <div className={classes.tables}>
          <Paper className={classes.side}>
            <InputLabel id='client'>Client</InputLabel>
            <Select id='client' className={classes.select} fullWidth value={selectedClient} onChange={e => setSelectedClient(e.target.value)}>
              {clients?.map(({ name, id, is_active }) => <MenuItem disabled={!is_active} key={id} value={id}>{name}</MenuItem>)}
            </Select>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableBody>
                  {projects?.map(project =>
                    <TableRow className={classes.row} key={client.client_id}>
                      <TableCell className={classes.projectsCell} style={!project?.is_active ? { color: 'gray' } : {}}>
                        <Radio disabled={!project?.is_active} checked={selectedProject === project?.id} onClick={() => setSelectedProject(project?.id)} />
                        <div className={classes.title}>
                          <Typography>{project?.name}</Typography>
                          <Typography className={classes.id}>{project?.id}</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className={classes.side}>
            <InputLabel id='type'>Assigne Type</InputLabel>
            <Select id='type' className={classes.select} value={type} onChange={e => setType(e.target.value)}>
              <MenuItem value='task'>Task</MenuItem>
              <MenuItem value='user'>User</MenuItem>
            </Select>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableBody>
                  {type === 'user' ?
                    users.map(user =>
                      <TableRow className={classes.row} key={client.client_id}>
                        <TableCell className={classes.cell}>
                          <div className={classes.info} style={userAssignments.find(({ user_id }) => user_id === user?.id)?.is_active === false ? { color: 'grey' } : {}}>
                            <Checkbox
                              disabled={working || selectedProject.length === 0}
                              checked={rightSelect.find(item => item === user.id) !== undefined} onClick={
                                () => selectedProject.length > 0 ? setRightSelect(rightSelect.find(item => item === user.id) ?
                                  rightSelect.filter(item => item !== user.id) : [...rightSelect, user.id]) : () => ({})()}
                            />
                            <div className={classes.title}>
                              <Typography>{user?.first_name} {user?.last_name} - {user?.email}</Typography>
                              <Typography className={classes.id}>{user?.id}</Typography>
                            </div>
                          </div>
                          {selectedProject?.length > 0 && rightSelect.find(item => item === user.id) !== undefined &&
                            <>
                              <Button onClick={() => openClose(user?.id)}>DETAIL ASSIGN</Button>
                              <Collapse in={openList.indexOf(user?.id) >= 0}>
                                <>
                                  <FormControlLabel control={<Switch checked={Boolean(assignments.find(({ user_id }) => user_id === user?.id)?.is_active)} onChange={e => updateUserAssignments(user?.id, 'is_active', { target: { value: !assignments.find(({ user_id }) => user_id === user?.id)?.is_active } })} />} label='Is active' />
                                  <FormControlLabel control={<Switch checked={Boolean(assignments.find(({ user_id }) => user_id === user?.id)?.is_project_manager)} onChange={e => updateUserAssignments(user?.id, 'is_project_manager', { target: { value: !assignments.find(({ user_id }) => user_id === user?.id)?.is_project_manager } })} />} label='Is project manager' />
                                  <FormControlLabel control={<Switch checked={Boolean(assignments.find(({ user_id }) => user_id === user?.id)?.use_default_rates)} onChange={e => updateUserAssignments(user?.id, 'use_default_rates', { target: { value: !assignments.find(({ user_id }) => user_id === user?.id)?.use_default_rates } })} />} label='Use default rates' />
                                  <InputLabel id='hourly_rate' className={classes.label}>Hourly Rate</InputLabel>
                                  <TextField disabled={assignments.find(({ user_id }) => user_id === user?.id)?.use_default_rates} type='number' id='hourly_rate' value={assignments.find(({ user_id }) => user_id === user?.id)?.hourly_rate} onChange={e => updateUserAssignments(user?.id, 'hourly_rate', e)} fullWidth required />
                                  <InputLabel id='budget' className={classes.label}>Budget</InputLabel>
                                  <TextField type='number' id='budget' value={assignments.find(({ user_id }) => user_id === user?.id)?.budget} onChange={e => updateUserAssignments(user?.id, 'budget', e)} fullWidth required />
                                </>
                              </Collapse>
                            </>}
                        </TableCell>
                      </TableRow>
                    ) : tasks.map(task =>
                      <TableRow className={classes.row} key={client.client_id}>
                        <TableCell className={classes.cell}>
                          <div className={classes.info} style={taskAssignments.find(({ task_id }) => task_id === task?.id)?.is_active === false ? { color: 'grey' } : {}}>
                            <Checkbox
                              disabled={working || selectedProject.length === 0}
                              checked={rightSelect.find(item => item === task.id) !== undefined} onClick={
                                () => selectedProject.length > 0 ? setRightSelect(rightSelect.find(item => item === task.id) ?
                                  rightSelect.filter(item => item !== task.id) : [...rightSelect, task.id]) : () => ({})()}
                            />
                            <div className={classes.title}>
                              <Typography>{task?.name}</Typography>
                              <Typography className={classes.id}>{task?.id}</Typography>
                            </div>
                          </div>
                          {selectedProject?.length > 0 && rightSelect.find(item => item === task.id) !== undefined &&
                            <>
                              <Button onClick={() => openClose(task?.id)}>DETAIL ASSIGN</Button>
                              <Collapse in={openList.indexOf(task?.id) >= 0}>
                                <>
                                  <FormControlLabel control={<Switch checked={Boolean(assignments.find(({ task_id }) => task_id === task?.id)?.is_active)} onChange={e => updateTaskAssignments(task?.id, 'is_active', { target: { value: !assignments.find(({ task_id }) => task_id === task?.id)?.is_active } })} />} label='Is active' />
                                  <FormControlLabel control={<Switch checked={Boolean(assignments.find(({ task_id }) => task_id === task?.id)?.billable)} onChange={e => updateTaskAssignments(task?.id, 'billable', { target: { value: !assignments.find(({ task_id }) => task_id === task?.id)?.billable } })} />} label='Billable' />
                                  <InputLabel id='hourly_rate' className={classes.label}>Hourly Rate</InputLabel>
                                  <TextField disabled={assignments.find(({ task_id }) => task_id === task?.id)?.use_default_rates} type='number' id='hourly_rate' value={assignments.find(({ task_id }) => task_id === task?.id)?.hourly_rate} onChange={e => updateTaskAssignments(task?.id, 'hourly_rate', e)} fullWidth required />
                                  <InputLabel id='budget' className={classes.label}>Budget</InputLabel>
                                  <TextField type='number' id='budget' value={assignments.find(({ task_id }) => task_id === task?.id)?.budget} onChange={e => updateTaskAssignments(task?.id, 'budget', e)} fullWidth required />
                                </>
                              </Collapse>
                            </>}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <Button className={classes.save} onClick={() => setOpenReviewModal(true)} disabled={!toRemove.length && !toAdd.length && !toUpdate.length}>Save</Button>
        <CreateEditClientModal
          open={openCreateEditClientModal}
          setOpen={setOpenCreateEditClientModal}
        />
        <AssignesReviewModal
          open={openReviewModal && type === 'user'}
          setOpen={setOpenReviewModal}
          confirm={() => {
            const userIds = toAdd.map(id => assignments.find(({ user_id }) => user_id === id) ?? { user_id: id })
            const assignmentIds = toRemove.map(user => userAssignments.find(({ user_id }) => user_id === user)?.id)
            const updates = toUpdate.map(({ id }) => assignments.find(a => a.id === id))
            dispatcher(project.actions.createUpdateOrRemoveUserAssignmentBatch(selectedProject, userIds, assignmentIds, updates))
            setOpenReviewModal(false)
          }}
          selection={rightSelect}
          currentSelection={userAssignments}
          classes={classes}
          project={projects.find(({ id }) => id === selectedProject)}
          toRemove={type === 'user' ? toRemove.map(user_id => users.find(({ id }) => user_id === id)) : []}
          toAdd={type === 'user' ? toAdd.map(user_id => users.find(({ id }) => user_id === id)) : []}
          toUpdate={type === 'user' ? toUpdate.map(a => users.find(({ id }) => a.user_id === id)) : []}
          type={type}
        />
        <AssignesReviewModal
          open={openReviewModal && type === 'task'}
          setOpen={setOpenReviewModal}
          confirm={() => {
            const taskIds = toAdd.map(id => assignments.find(({ task_id }) => task_id === id) ?? { task_id: id })
            const assignmentIds = toRemove.map(task => taskAssignments.find(({ task_id }) => task_id === task)?.id)
            const updates = toUpdate.map(({ id }) => assignments.find(a => a.id === id))
            dispatcher(project.actions.createUpdateOrRemoveTaskAssignmentBatch(selectedProject, taskIds, assignmentIds, updates))
            setOpenReviewModal(false)
          }}
          selection={rightSelect}
          currentSelection={userAssignments}
          classes={classes}
          project={projects.find(({ id }) => id === selectedProject)}
          toRemove={type === 'task' ? toRemove.map(task_id => tasks.find(({ id }) => task_id === id)) : []}
          toAdd={type === 'task' ? toAdd.map(task_id => tasks.find(({ id }) => task_id === id)) : []}
          toUpdate={type === 'task' ? toUpdate.map(a => tasks.find(({ id }) => a.task_id === id)) : []}
          type={type}
        />
      </>

    </div >
  )
}

const AssignesReviewModal = ({ open, setOpen, confirm, classes, project, toRemove, toAdd, toUpdate, type }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className={classes.box}>
        <Typography>Are you sure you want to save the <b>{project?.name}'s</b> assignes?</Typography>
        {
          toRemove.length > 0 && open &&
          <>
            <Typography>Remove Assignes: </Typography>
            <List style={{ maxHeight: '200px', overflow: 'auto', marginBottom: '10px' }}>
              {type === 'user' ?
                toRemove.map(({ first_name, last_name, email }) =>
                  <ListItem><Typography>{first_name} {last_name} - {email}</Typography></ListItem>) :
                toRemove.map(({ name, id }) =>
                  <ListItem><Typography>{name} - {id}</Typography></ListItem>)
              }
            </List>
          </>
        }
        {
          toAdd.length > 0 && open &&
          <>
            <Typography>Add Assignes: </Typography>
            <List style={{ maxHeight: '200px', overflow: 'auto', marginBottom: '10px' }}>
              {type === 'user' ?
                toAdd.map(({ first_name, last_name, email }) =>
                  <ListItem ><Typography>{first_name} {last_name} - {email}</Typography></ListItem>) :
                toAdd.map(({ name, id }) =>
                  <ListItem ><Typography>{name} - {id}</Typography></ListItem>)
              }
            </List>
          </>
        }
        {
          toUpdate.length > 0 && open &&
          <>
            <Typography>Update Assignes: </Typography>
            <List style={{ maxHeight: '200px', overflow: 'auto', marginBottom: '10px' }}>
              {type === 'user' ?
                toUpdate.map(({ first_name, last_name, email }) =>
                  <ListItem><Typography>{first_name} {last_name} - {email}</Typography></ListItem>) :
                toUpdate.map(({ name, id }) =>
                  <ListItem><Typography>{name} - {id}</Typography></ListItem>)
              }
            </List>
          </>
        }
        <div className={classes.buttons}>
          <Button onClick={confirm}>Save</Button>
          <Button onClick={() => setOpen(false)} className={classes.cancel}>Cancel</Button>
        </div>
      </Box >
    </Modal >
  )
}

export default AdminAssignes
