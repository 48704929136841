import React from 'react'
import { WorkingHoursFilter, WorkingHoursGeneralInfo, WorkingHoursTable } from '../../components'
import { useStyles } from './style'

const WorkingHours = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <WorkingHoursFilter />
        <div style={{ display: 'flex', justifyItems: 'space-between' }}>
          <WorkingHoursTable />
          <WorkingHoursGeneralInfo />
        </div>
      </div>
    </div>
  )
}

export default WorkingHours
