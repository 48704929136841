import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import name from './name'

// Store
export const Entries = createSlice({
  name,
  initialState: {
    entries: [],
    projects: [],
    collaborators: [],
    clients: [],
    combinations: [],
    tasks: [],
    monthRanges: []
  },
  reducers: {
    setEntries: (state, { payload: { time_entries: timeEntries } }) => {
      state.entries = timeEntries
      state.projects = selectUniques(timeEntries, 'project', 'id')
      state.collaborators = selectUniques(timeEntries, 'user', 'id')
      state.clients = selectUniques(timeEntries, 'client', 'id')
      state.tasks = selectUniques(timeEntries, 'task', 'id')
      state.combinations = Array.from(new Set(timeEntries?.map(({ user, client, project }) => JSON.stringify({ user, client, project })))).map(item => JSON.parse(item))
      state.monthRanges = Array.from(new Set(timeEntries.map(
        ({ spent_date: spentDate }) => moment(spentDate).startOf('month').format('YYYY-MM-DD').toString()))
      ).map((date) =>
        ({ start: moment(date).startOf('month').format('YYYY-MM-DD').toString(), end: moment(date).endOf('month').format('YYYY-MM-DD').toString() }))
    }
  }
})

const selectUniques = (timeEntries, item, idName) => {
  const items = timeEntries?.map(payloadItem => payloadItem[item])
  return Array.from(new Set(
    items?.map(payloadItem => payloadItem[idName]))
  ).map(payloadItemId => items.find(({ id }) => payloadItemId === id))
}

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setEntries
} = Entries.actions

// Reducer
export default Entries.reducer
