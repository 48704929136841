import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  filter: {
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  search: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between'
  },
  attach: {
    display: 'flex',
    padding: '10px',
    border: '10px',
    margin: '10px',
    height: '90%',
    backgroundColor: 'black'
  },
  filterPaymentsExtra: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    "&:hover": { backgroundColor: theme.palette.primary.main },
    borderRadius: '0px'
  },
  button: {
    "&:hover": { backgroundColor: '#595959' },
    borderRadius: '0px',
    backgroundColor: '#595959',
  }
}))
