import React from 'react'
import { useStyles } from './style'
import EntriesSlice from '../../reducers/entries'
import WorkingHoursSlice from '../../reducers/workinghours'
import { useDispatch, useSelector } from 'react-redux'
import timeEntries from '../../__mocks__/time_entries.json'
import { Button, Card, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import moment from 'moment'

export default function WorkingHoursFilter () {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const entries = useSelector(EntriesSlice.selectors.selectEntries)
  const projects = useSelector(EntriesSlice.selectors.selectProjects)
  const collaborators = useSelector(EntriesSlice.selectors.selectCollaborators)
  const clients = useSelector(EntriesSlice.selectors.selectClients)
  const filteredProjects = useSelector(WorkingHoursSlice.selectors.selectFilteredProjects)
  const filteredCollaborators = useSelector(WorkingHoursSlice.selectors.selectFilteredCollaborators)
  const filteredClients = useSelector(WorkingHoursSlice.selectors.selectFilteredClients)
  const startDate = useSelector(WorkingHoursSlice.selectors.selectStartDate)
  const endDate = useSelector(WorkingHoursSlice.selectors.selectEndDate)
  const combinations = useSelector(EntriesSlice.selectors.selectCombinations)
  const filteredTasks = useSelector(WorkingHoursSlice.selectors.selectFilteredTasks)
  const tasks = useSelector(EntriesSlice.selectors.selectTasks)
  const search = useSelector(WorkingHoursSlice.selectors.selectSearch)
  const [localSearch, setLocalSearch] = React.useState('')

  const setFilteredProjects = ({ target: { value } }) => {
    dispatcher(WorkingHoursSlice.actions.setFilteredProjects(value.map(id => projects.find(project => project.id === id))))
  }
  const setFilteredCollaborators = ({ target: { value } }) => {
    dispatcher(WorkingHoursSlice.actions.setFilteredCollaborators(value.map(id => collaborators.find(collaborator => collaborator.id === id))))
  }
  const setFilteredClients = ({ target: { value } }) => {
    dispatcher(WorkingHoursSlice.actions.setFilteredClients(value.map(id => clients.find(client => client.id === id))))
  }
  const setStartDate = ({ target: { value } }) => {
    if (!endDate || moment(value) <= moment(endDate)) {
      dispatcher(WorkingHoursSlice.actions.setStartdate(value))
    }
  }
  const setEndDate = ({ target: { value } }) => {
    if (!startDate || moment(value) >= moment(startDate)) {
      dispatcher(WorkingHoursSlice.actions.setEndDate(value))
    }
  }
  const applySearch = () => {
    dispatcher(WorkingHoursSlice.actions.setSearch(localSearch))
  }

  React.useEffect(() => {
    const selectEntries = entries
      .filter(entry => search.length === 0 || JSON.stringify(entry).toLocaleLowerCase().indexOf(search.toLowerCase()) >= 0)
      .filter(({ client }) => !filteredClients.length || filteredClients.find(({ id }) => client.id === id))
      .filter(({ project }) => !filteredProjects.length || filteredProjects.find(({ id }) => project.id === id))
      .filter(({ user }) => !filteredCollaborators.length || filteredCollaborators.find(({ id }) => user.id === id))
      .filter(({ task }) => !filteredTasks.length || filteredTasks.find(({ id }) => task.id === id))
      .filter(({ spent_date: spentDate }) => {
        if (startDate && endDate) return moment(spentDate) >= moment(startDate) && moment(spentDate) <= moment(endDate)
        if (startDate) return moment(spentDate) >= moment(startDate)
        if (endDate) return moment(spentDate) >= moment(endDate)
        return true
      })
    dispatcher(WorkingHoursSlice.actions.setFilteredEntries(selectEntries))

    const groupEntries = combinations
      .map(({ user: { id: userId }, project: { id: projectId }, client: { id: clientId } }) =>
        selectEntries
          .filter(({ user, client, project }) => user.id === userId && client.id === clientId && project.id === projectId)
          .reduce((acc, curr) => ({ ...curr, hours: acc.hours + (curr.hours ?? 0) }), { hours: 0 })
      ).filter(({ id }) => id)
    dispatcher(WorkingHoursSlice.actions.setGroupedEntries(groupEntries))

    const lastThreeMonthsStartDate = moment().startOf('month').subtract(3, 'months')
    const lastThreeMonthsEndDate = moment().endOf('month').subtract(1, 'months')
    const lastThreeMonthsEntries = entries
      .filter(({ client }) => !filteredClients.length || filteredClients.find(({ id }) => client.id === id))
      .filter(({ project }) => !filteredProjects.length || filteredProjects.find(({ id }) => project.id === id))
      .filter(({ user }) => !filteredCollaborators.length || filteredCollaborators.find(({ id }) => user.id === id))
      .filter(({ spent_date: spentDate }) => moment(spentDate) >= moment(lastThreeMonthsStartDate) && moment(spentDate) <= moment(lastThreeMonthsEndDate))
    dispatcher(WorkingHoursSlice.actions.setLastThreeMonthsEntries(lastThreeMonthsEntries))
    const lastThreeMonthsEntriesSumByMonth = [1, 2, 3].map(
      subtract => {
        const range = { start: moment().startOf('month').subtract(subtract, 'months'), end: moment().endOf('month').subtract(subtract, 'months') }
        return combinations
          .map(({ user: { id: userId }, project: { id: projectId }, client: { id: clientId } }) =>
            lastThreeMonthsEntries
              .filter(({ client }) => client?.id === clientId)
              .filter(({ project }) => project?.id === projectId)
              .filter(({ user }) => user?.id === userId)
              .filter(({ spent_date: spentDate }) => moment(spentDate) >= moment(range.start) && moment(spentDate) <= moment(range.end))
              .reduce((acc, curr) => ({ ...curr, hours: acc.hours + (curr.hours ?? 0) }), { hours: 0 }))
      }
    )
    dispatcher(WorkingHoursSlice.actions.setLastThreeMonthsEntriesSumByMonth(lastThreeMonthsEntriesSumByMonth))
    const lastThreeMonthsGroupEntries = combinations
      .map(({ user: { id: userId }, project: { id: projectId }, client: { id: clientId } }) =>
        lastThreeMonthsEntries
          .filter(({ user, client, project }) => user.id === userId && client.id === clientId && project.id === projectId)
          .reduce((acc, curr) => ({ ...curr, hours: acc.hours + (curr.hours ?? 0) }), { hours: 0 })
      ).filter(({ id }) => id)
    dispatcher(WorkingHoursSlice.actions.setLastThreeMonthsGroupEntries(lastThreeMonthsGroupEntries))
  }, [dispatcher, entries, filteredProjects, filteredCollaborators, filteredClients, startDate, endDate, clients, collaborators, projects, combinations, filteredTasks, tasks, search])
  React.useEffect(() => {
    dispatcher(EntriesSlice.actions.setEntries(timeEntries))
  }, [dispatcher])
  return (
    <Card className={classes.filter}>
      <div className={classes.input}>
        <InputLabel>Project</InputLabel>
        <Select
          value={filteredProjects.map(({ id }) => id)}
          label='Project'
          onChange={setFilteredProjects}
          className={classes.select}
          multiple
        >
          {projects.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
        </Select>
      </div>
      <div className={classes.input}>
        <InputLabel>Collaborator</InputLabel>
        <Select
          value={filteredCollaborators.map(({ id }) => id)}
          label='Collaborator'
          onChange={setFilteredCollaborators}
          className={classes.select}
          multiple
        >
          {collaborators.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
        </Select>
      </div>
      <div className={classes.input}>
        <InputLabel>Clients</InputLabel>
        <Select
          value={filteredClients.map(({ id }) => id)}
          label='Client'
          onChange={setFilteredClients}
          className={classes.select}
          multiple
        >
          {clients.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
        </Select>
      </div>
      <div className={classes.input}>
        <InputLabel>Start Date</InputLabel>
        <TextField className={classes.select} onChange={setStartDate} value={startDate} type='date' name='start_date' />
      </div>
      <div className={classes.input}>
        <InputLabel>End Date</InputLabel>
        <TextField className={classes.select} onChange={setEndDate} value={endDate} type='date' name='end_date' />
      </div>
      <div className={classes.input}>
        <InputLabel>Search</InputLabel>
        <div className={classes.search}>
          <TextField type='text' value={localSearch} onChange={({ target: { value } }) => setLocalSearch(value)} />
          <Button style={{ padding: '1px' }} variant='contained' onClick={applySearch}>Search</Button>
        </div>
      </div>
    </Card>
  )
}
