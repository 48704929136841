import { createSlice } from '@reduxjs/toolkit'
import consts from '../../consts'
import { HarvestCloneApi } from '../../services/api'
import { sortActives } from '../../utils'
import name from './name'

export const taskSlice = createSlice({
  name,
  initialState: {
    tasks: [],
    isWorking: false
  },
  reducers: {
    setTasks: (state, { payload }) => {
      state.tasks = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    }
  }
})

export const {
  setTasks,
  setIsWorking
} = taskSlice.actions

export const fetchTasks = () => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.get('tasks')
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setTasks(result.data.sort(sortActives) || []))
    }
  } catch (error) {
    console.log(error)
  }
  dispatcher(setIsWorking(false))
}

export const updateTask = (id, task) => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.patch(`tasks/${id}`, task)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchTasks())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const createTask = task => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.post('tasks', task)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchTasks())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export const deleteTask = id => async (dispatcher) => {
  dispatcher(setIsWorking(true))
  try {
    const result = await HarvestCloneApi.delete(`tasks/${id}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(fetchTasks())
    }
  } catch (error) {
    console.log(error)
    dispatcher(setIsWorking(false))
  }
}

export default taskSlice.reducer
